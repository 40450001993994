import { Component, OnInit } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GetViewProfilePartnerPreferencesDto } from "src/app/dto/GetViewProfilePartnerPreferencesDto";

@Component({
  selector: 'view-profile-partner-preference',
  templateUrl: './view-profile-partner-preference.component.html',
  styleUrls: ['./view-profile-partner-preference.component.css']
})
export class ViewProfilePartnerPreferenceComponent implements OnInit {
  userloggedIn: string;
  ui: GetViewProfilePartnerPreferencesDto;
  showLoader = true;

  constructor(private commonService: Commonservice, private localStore: LocalStore) {
  }


  ngOnInit() {
    const self = this;
    self.showLoader = true;
    self.ui = {} as GetViewProfilePartnerPreferencesDto;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getViewProfilePartnerPreferences(self.userloggedIn).subscribe(
        data => {
          self.ui = data;
          self.showLoader = false;
        },
        error => {}
      );
    });
  
  }
}

