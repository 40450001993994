import { Component, inject, Input, OnInit } from '@angular/core';
import { Dropdown } from "src/app/dto/Dropdown";
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { SaveResponse } from "src/app/dto/SaveResponse";
import { GetEditProfileJobDto } from "src/app/dto/GetEditProfileJobDto";
import * as _ from 'lodash';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { dropdown } from 'src/app/dto/dropdown.1';
import { forkJoin } from 'rxjs';
import { LoaderService } from 'src/app/common/reusable/servicecenter/LoaderService';

@Component({
  selector: 'app-edit-job-profile',
  templateUrl: './edit-job-profile.component.html',
  styleUrls: ['./edit-job-profile.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class EditJobProfileComponent implements OnInit {
  @Input() epd: EditPageMegaBucket = {} as EditPageMegaBucket;
  uic: EditPageMegaBucket;
  ui: GetEditProfileJobDto;
  userloggedIn: string;
  srm: SaveResponse;
  job: dropdown[] = [];
  jobType: dropdown[] = [];
  visaStatus: dropdown[] = [];
  jobSelectedValue: Dropdown;
  jobTypeSelectedValue: Dropdown;
  visaStatusSelectedValue: Dropdown;
  countrySelectedValue: Dropdown;
  citySelectedValue: Dropdown;
  stateSelectedValue: Dropdown;
  nextLeave: Date;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, public loadingService: LoaderService) { }

  ngOnInit(): void {
    const self = this;
    self.uic = {} as EditPageMegaBucket;
    self.ui = {} as GetEditProfileJobDto;
    self.uic = self.epd;
    self.getDataFromDb();
  }
  getDataFromDb() {
    const self = this;
    const ccn = self.commonService;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      this.loadingService.startLoading();
      ccn.getEditProfileJob(self.userloggedIn).subscribe({
        next: (response) => {
          self.ui = response;        
          self.setSelected();
        },
        error: (error) => {
          // Handle error
        },
        complete: () => {
          this.loadingService.stopLoading();
        }
      });
    });
  }

  setSelected() {
    const self = this;  
    if (self.ui.Job != undefined) {
      self.jobSelectedValue = self.uic.Jobs.find(x => x.Value.toString() === self.ui.Job.toString());
    }
    else {
      self.jobSelectedValue = self.uic.Jobs.find(x => x.Value.toString() === '0');
    }

    if (self.ui.JobType != undefined) {
      self.jobTypeSelectedValue = self.uic.JobCategory.find(x => x.Value.toString() === self.ui.JobType.toString());
    }
    else {
      self.jobTypeSelectedValue = self.uic.JobCategory.find(x => x.Value.toString() === '0');
    }

    if (self.ui.VisaStatus != undefined) {
      self.visaStatusSelectedValue = self.uic.VisaStatus.find(x => x.Value.toString() === self.ui.VisaStatus.toString());
    }
    else {
      self.visaStatusSelectedValue = self.uic.VisaStatus.find(x => x.Value.toString() === '0');
    }

    if (self.ui.NextLeave != undefined) {
      self.nextLeave = new Date(self.ui.NextLeave);
    }
  }

  saveInformation() {
    const self = this;
    let getEditProfileJobDto: GetEditProfileJobDto = {} as GetEditProfileJobDto;
    getEditProfileJobDto.Job = self.jobSelectedValue ? self.jobSelectedValue.Value : 0;
    getEditProfileJobDto.JobType = self.jobTypeSelectedValue ? self.jobTypeSelectedValue.Value : 0;
    getEditProfileJobDto.VisaStatus = self.visaStatusSelectedValue ? self.visaStatusSelectedValue.Value : 0;
    getEditProfileJobDto.NextLeave = self.nextLeave;
    getEditProfileJobDto.Firm = self.ui.Firm;
    getEditProfileJobDto.AnnualIncome = self.ui.AnnualIncome;
    getEditProfileJobDto.UserLoggedIn = self.userloggedIn;

    this.commonService.setEditJobDetails(getEditProfileJobDto).subscribe(
      data => {
        self.srm = data;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.srm.Message });
        self.getDataFromDb();
      },
      error => { }
    );
  }
}
