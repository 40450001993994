import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable()
export class MenuService {
  connect = environment.apiUrl;
  constructor(private httpClient: HttpClient) {}
  getMenuFromServer() {
    const theresult = this.httpClient.get(this.connect + '/api/Menu/getMenu');
    return theresult;
  }
}
