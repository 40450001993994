<div class="menu-space">
  <ul class="">
    <li class="left-menu-header">My home</li>
    <li role="presentation" class="my-menu-header" *ngFor="let item of homeMenu; let i = index"
      routerLinkActive="active"><a [routerLink]="[item.RoutePath]"
        (contextmenu)="disableRightClick($event)">{{item.Name}}</a></li>
  </ul>
  <ul class="">
    <li class="left-menu-header">My search</li>
    <li role="presentation" class="my-menu-header" *ngFor="let item of searchMenu; let i = index"
      routerLinkActive="active"><a [routerLink]="[item.RoutePath]"
        (contextmenu)="disableRightClick($event)">{{item.Name}}</a></li>
  </ul>

  <ul class="">
    <li class="left-menu-header">My messages</li>
    <li role="presentation" class="my-menu-header" *ngFor="let item of myMessageInbox; let i = index"
      routerLinkActive="active"><a [routerLink]="[item.RoutePath]"
        (contextmenu)="disableRightClick($event)">{{item.Name}}</a></li>
  </ul>

  <ul class="">
    <li class="left-menu-header">Express interest</li>
    <li role="presentation" class="my-menu-header" *ngFor="let item of expressInterests; let i = index"
      routerLinkActive="active"><a [routerLink]="[item.RoutePath]"
        (contextmenu)="disableRightClick($event)">{{item.Name}}</a></li>
  </ul>
  <ul class="">
    <li class="left-menu-header">My profile</li>
    <li role="presentation" class="my-menu-header" *ngFor="let item of profileMenu; let i = index"
      routerLinkActive="active"><a [routerLink]="[item.RoutePath]"
        (contextmenu)="disableRightClick($event)">{{item.Name}}</a></li>
  </ul>
  <ul class="">
    <li class="left-menu-header">My vault</li>
    <li role="presentation" class="my-menu-header" *ngFor="let item of storageVault; let i = index"
      routerLinkActive="active"><a [routerLink]="[item.RoutePath]"
        (contextmenu)="disableRightClick($event)">{{item.Name}}</a></li>
  </ul>
</div>