import { trigger, transition, style, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';
import { ActionResponse } from 'src/app/dto/ActionResponse';
import { ExpressInterestCarrier } from 'src/app/dto/ExpressInterestCarrier';
import { ExpressInterestModel } from 'src/app/dto/ExpressInterestModel';
import { ExpressInterestSendModel } from 'src/app/dto/ExpressInterestSendModel';
import { ListOfusersBookMarkedMiniModel } from "src/app/dto/ListOfusersBookMarkedMiniModel";
import { RemoveBookmarkModel } from 'src/app/dto/RemoveBookmarkModel';
import { SendMessageGeneric } from 'src/app/dto/SendMessageGeneric';
import { UserAction } from 'src/app/dto/UserAction';

@Component({
  selector: 'home-bookmark-profile',
  templateUrl: './home-bookmark-profile.component.html',
  styleUrls: ['./home-bookmark-profile.component.css'],
  providers: [MessageService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeScaleAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.97)' }),
        animate('700ms ease-in-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('700ms ease-in-out', style({ opacity: 0, transform: 'scale(0.95)' }))
      ])
    ]),
    trigger('fadeScaleAnimation2', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.50)' }),
        animate('700ms ease-in-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('700ms ease-in-out', style({ opacity: 0, transform: 'scale(0.95)' }))
      ])
    ])
  ]
})
export class HomeBookmarkProfileComponent implements OnInit {
  bookMarkedUsers: Array<ListOfusersBookMarkedMiniModel> = [];
  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;
  userloggedIn: string;
  showLargeImage = false;
  userId: string;
  imageToDisplay: HostWindowModel;
  confirmationTitle: string = "Confirmation";
  bookmarkPage: number = 1;
  showLoader = false;
  showImageLoader = false;
  smg: SendMessageGeneric;
  sendMessageText: string;
  mtd: ExpressInterestModel;
  expressInterestModel: ExpressInterestSendModel;
  removeBookmarkModel: RemoveBookmarkModel;
  showSettingsIcon: boolean = true;
  userAction: UserAction;


  pagedItems: any[] = []; // Items to display on the current page
  totalRecords: number = 0;
  rows: number = 5; // Items per page

  constructor(private commonService: Commonservice, private localStore: LocalStore, private datePipe: DatePipe, private messageService: MessageService, private router: Router, private commonservice: Commonservice,
    private confirmationService: ConfirmationService, private loaderService: GlobalLoaderService
  ) { }

  ngOnInit() {
    const self = this;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.userloggedIn = self.userloggedIn;
    self.bookMarkedUsers = [];
    self.imageToDisplay = {} as HostWindowModel;
    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.mtd = {} as ExpressInterestModel;
    // this.loaderService.loader$.subscribe(loader => {
    //   this.showLoader = loader;
    // });
    self.loadBookMarks();




  }

  loadBookMarks() {
    const self = this;
    // this.loaderService.showLoader();
    self.commonService.getUserBookProfileForHomePage(self.userloggedIn).subscribe(
      data => {
        self.bookMarkedUsers = data;
        self.bookMarkedUsers.map(x => x.showIconEngine = true);
        this.totalRecords = this.bookMarkedUsers.length;
        // Set initial pagination
        this.paginate({ first: 0, rows: this.rows });
        // this.loaderService.hideLoader();
      },
      error => {

      }
    );
  }


  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    this.showLoader = true;
    if (itemId != null) {
      // this.loaderService.showLoader();
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          self.showLargeImage = true;
          this.showLoader = false;
          // this.loaderService.hideLoader();
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {

        }
      );
    }
    else {
      // this.loaderService.hideLoader();
      this.messageService.add({ severity: 'info', summary: 'Service Message', detail: "no image" });
    }
  }

  navigateToDetails(tc: ListOfusersBookMarkedMiniModel) {
    let link = "./../viewotherprofile" + "/" + tc.userWhoGotBookMarked;
    this.router.navigate([]).then(() => { window.open(link, '_blank'); });
  }

  showSendDialogue(mc: ListOfusersBookMarkedMiniModel) {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = mc.userWhoGotBookMarked;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonservice.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }

  eventPostSubmit(event: ActionResponse) {
    const self = this;
    self.smg.ShowSendMessage = false;
    if (event.ActionState == true) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }

  expressInterestPop(event: Event, row) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = row.userWhoGotBookMarked;
    eic.userToName = row.NameOfPersonWhoGotBookmarked;
    self.mtd = self.commonservice.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure express interest?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  sendExpressInterest(event) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonservice.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data.InsertWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Message });
        }
      });
    }
  }

  removeBookmark(event: Event, bookmark: ListOfusersBookMarkedMiniModel) {
    const self = this;
    self.confirmationTitle = "Confirmation";
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete the bookmark?',
      accept: () => {
        self.removeBookmarkModel = {} as RemoveBookmarkModel;
        self.removeBookmarkModel.bookmarkId = bookmark.bookMarkId;
        self.loaderService.showLoader();
        self.commonservice.removeBookMark(self.removeBookmarkModel).subscribe(dataMain => {
          self.loaderService.hideLoader();
          if (dataMain.DeleteWorked) {
            self.commonService.getUserBookProfileForHomePage(self.userloggedIn).subscribe(
              data => {
                self.bookMarkedUsers = data;
                self.messageService.add({ severity: 'success', summary: 'Service Message', detail: dataMain.Message });
              },
              error => {

              }
            );
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Service Message', detail: dataMain.Message });
          }
        });
      }
    });
  }

  setShowIcon(item: ListOfusersBookMarkedMiniModel) {
    item.showIconEngine = !item.showIconEngine;
  }

  triggerParentRefresh(refresh: boolean) {
    if (refresh) {
      this.loadBookMarks();
    }
  }

  // onPageChange(pageNumber: number): void {
  //   setTimeout(() => {
  //     this.bookmarkPage = pageNumber;
  //   }, 270);
  // }

  paginate(event: any) {
    const start = event.first;
    const end = start + event.rows;
    this.pagedItems = this.bookMarkedUsers.slice(start, end);
  }
}
