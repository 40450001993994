<ng-container [ngSwitch]="currentDeviceState">

  <ng-container *ngSwitchCase="'desktop-landscape'">
    <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'mobile-portrait'">
    <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'tablet-portrait'">
    <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
  </ng-container>

</ng-container>


<ng-template #mobilePortrait>
  <div class='menu-case'>

    <div class='menu-items-mobile'>
      <a class='menu-link' [routerLink]=menuArray[0].Path>
        <div class='menuBackGround my-top-menu-button-mobile full-width'>
          {{menuArray[0].Name}}
        </div>
      </a>
    </div>
    <div class='menu-items-mobile'>
      <a class='menu-link' [routerLink]=menuArray[1].Path>
        <div class='menuBackGround my-top-menu-button-mobile full-width'>
          {{menuArray[1].Name}}
        </div>
      </a>
    </div>
    <div class='menu-items-mobile'>
      <a class='menu-link' [routerLink]=menuArray[2].Path>
        <div class='menuBackGround my-top-menu-button-mobile full-width'>
          {{menuArray[2].Name}}
        </div>
      </a>
    </div>
    <div class='menu-items-mobile'>
      <a class='menu-link' [routerLink]=menuArray[3].Path>
        <div class='menuBackGround my-top-menu-button-mobile full-width'>
          {{menuArray[3].Name}}
        </div>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #desktopLandscape>
  <div class='menu-case'>

    <div class='menu-items'>
      <a class='menu-link' [routerLink]=menuArray[0].Path>
        <div class='menuBackGround my-top-menu-button full-width'>
          {{menuArray[0].Name}}
        </div>
      </a>
    </div>
    <div class='menu-items'>
      <a class='menu-link' [routerLink]=menuArray[1].Path>
        <div class='menuBackGround my-top-menu-button full-width'>
          {{menuArray[1].Name}}
        </div>
      </a>
    </div>
    <div class='menu-items'>
      <a class='menu-link' [routerLink]=menuArray[2].Path>
        <div class='menuBackGround my-top-menu-button full-width'>
          {{menuArray[2].Name}}
        </div>
      </a>
    </div>
    <div class='menu-items'>
      <a class='menu-link' [routerLink]=menuArray[3].Path>
        <div class='menuBackGround my-top-menu-button full-width'>
          {{menuArray[3].Name}}
        </div>
      </a>
    </div>
    <div class='menu-items'>
      <a class='menu-link' [routerLink]=menuArray[4].Path>
        <div class='menuBackGround my-top-menu-button full-width'>
          {{menuArray[4].Name}}
        </div>
      </a>
    </div>
    <div class='menu-items'>
      <a class='menu-link' [routerLink]=menuArray[5].Path>
        <div class='menuBackGround my-top-menu-button full-width'>
          {{menuArray[5].Name}}
        </div>
      </a>
    </div>
  </div>
</ng-template>