// data.service.ts
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignalStateService } from './StateService';
import { APP_CONSTANTS } from '../contants/app.constants';

@Injectable({
    providedIn: 'root',
})
export class PrefetchBackGroundService {
    connect = environment.apiUrl;
    userloggedIn: string;
    constructor(private signalStateService: SignalStateService) { }

    updateBasicProfileInStore(data) {
        this.signalStateService.setValue(APP_CONSTANTS.BasicProfileDataSet, JSON.stringify(data));
    }

    storeResolutionRelatedData(data) {
        this.signalStateService.setValue(APP_CONSTANTS.ResolutionData, data);
    }

    // updateHomePageBookInStore(data) {
    //     this.signalStateService.setValue(APP_CONSTANTS.HomePageBookMark, JSON.stringify(data));
    // }

    // setWhoViewedMyProfileFromData(data) {
    //     this.signalStateService.setValue(APP_CONSTANTS.HomePageMembersWhoViewedMyProfile, JSON.stringify(data));
    // }

    // setContactsViewedByMeFromData(data) {
    //     this.signalStateService.setValue(APP_CONSTANTS.HomePageContactsViewedByMe, JSON.stringify(data));
    // }

    // setRecentVistorsToMyProfileFromData(data) {
    //     this.signalStateService.setValue(APP_CONSTANTS.RecentVistorsToMyProfile, JSON.stringify(data));
    // }

    // setMyRecentVisitsFromData(data){
    //     this.signalStateService.setValue(APP_CONSTANTS.MyRecentVisits, JSON.stringify(data));
    // }

    // setSavedSearchesFromData(data){
    //     this.signalStateService.setValue(APP_CONSTANTS.MySavedSearches, JSON.stringify(data));
    // }
}
