import { trigger, transition, style, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';
import { ActionResponse } from 'src/app/dto/ActionResponse';
import { ExpressInterestCarrier } from 'src/app/dto/ExpressInterestCarrier';
import { ExpressInterestModel } from 'src/app/dto/ExpressInterestModel';
import { ExpressInterestSendModel } from 'src/app/dto/ExpressInterestSendModel';
import { RecentVisitorsToMyProfileModel } from "src/app/dto/RecentVisitorsToMyProfileModel";
import { SendMessageGeneric } from 'src/app/dto/SendMessageGeneric';

@Component({
  selector: 'home-recent-visitor-to-my-profile',
  templateUrl: './home-recent-visitor-to-my-profile.component.html',
  styleUrls: ['./home-recent-visitor-to-my-profile.component.css'],
  providers: [MessageService, ConfirmationService],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class HomeRecentVisitorToMyProfileComponent implements OnInit {
  recentVisitorsToYourProfile: Array<RecentVisitorsToMyProfileModel>;
  userloggedIn: string;
  RecentVisitorsToYourProfileNumber = 1;
  showLargeImage = false;
  userId: string;
  imageToDisplay: HostWindowModel;
  showLoader = false;

  mtd: ExpressInterestModel;
  confirmationTitle: string = "Confirmation";
  expressInterestModel: ExpressInterestSendModel;
  smg: SendMessageGeneric;
  sendMessageText: string;
  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private confirmationService: ConfirmationService, private messageService: MessageService, private datePipe: DatePipe, private router: Router, private loaderService: GlobalLoaderService
  ) { }

  ngOnInit() {
    const self = this;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.userloggedIn = self.userloggedIn;
    self.recentVisitorsToYourProfile = [];
    self.imageToDisplay = {} as HostWindowModel;

    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.mtd = {} as ExpressInterestModel;
    self.bindGrid();
  }

  bindGrid() {
    const self = this;
    // this.loaderService.showLoader();
    self.commonService.getRecentVisitorsToYourProfile(self.userloggedIn).subscribe(
      data => {
        self.recentVisitorsToYourProfile = data;
        self.recentVisitorsToYourProfile.map(x => x.showIconEngine = true);
        // this.loaderService.hideLoader();
      },
      error => {

      }
    );
  }

  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (itemId != null) {
      // this.loaderService.showLoader();
      self.showLargeImage = false;
      self.showLoader = true;
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          // this.loaderService.hideLo  ader();
          self.showLoader = false;
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {

        }
      );
    }
    else {

    }
  }




  expressInterestPop(event: Event, row: RecentVisitorsToMyProfileModel) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = row.IdOfPersonIVisited;
    eic.userToName = row.NameOfPersonWhoVisitedMe;
    self.mtd = self.commonService.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure express interest?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  sendExpressInterest(event) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonService.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data.InsertWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Message });
        }
      });
    }
  }

  showSendDialogue(mc: RecentVisitorsToMyProfileModel) {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = mc.IdOfPersonIVisited;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonService.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  navigateToDetails(tc: RecentVisitorsToMyProfileModel) {
    let link = "./../viewotherprofile" + "/" + tc.IdOfPersonIVisited;
    this.router.navigate([]).then(() => { window.open(link, '_blank'); });
  }

  setShowIcon(item: RecentVisitorsToMyProfileModel) {
    item.showIconEngine = !item.showIconEngine;
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }

  eventPostSubmit(event: ActionResponse) {
    const self = this;
    self.smg.ShowSendMessage = false;
    if (event.ActionState == true) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }

  deleteItem(cdvm: RecentVisitorsToMyProfileModel) {
    const self = this;
    self.confirmationTitle = "Confirmation";
    const id = parseInt(cdvm.ID);
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete ' + cdvm.NameOfPersonWhoVisitedMe + ' ?',
      accept: () => {
        self.commonService.deleteRecentVisitorsToMyProfile(id).subscribe(dataMain => {
          if (dataMain.DeleteWorked) {
            self.bindGrid();
            this.messageService.add({ severity: 'success', summary: 'Service Message', detail: dataMain.Message });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Service Message', detail: dataMain.Message });
          }
        });
      }
    });
  }

  onPageChange(pageNumber: number): void {
    setTimeout(() => {
      this.RecentVisitorsToYourProfileNumber = pageNumber;
    }, 270);
  }
}
