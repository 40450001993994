import { Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingCount = 0;
  isLoading = signal(false);
  private isStopped = false;

  startLoading() {
    if (this.isStopped) {
      return;
    }
    this.loadingCount++;
    this.updateLoadingState();
  }

  stopLoading() {
    if (this.loadingCount > 0) {
      this.loadingCount--;
      if (this.loadingCount === 0) {
        this.isStopped = true;
      }
      this.updateLoadingState();
    } 
  }

  private updateLoadingState() {
    const newLoadingState = this.loadingCount > 0;
    this.isLoading.set(newLoadingState);
  }

  resetLoading() {
    this.loadingCount = 0;
    this.isStopped = false;
    this.updateLoadingState();
  }
}