import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { ConfirmdeletemodelComponent } from 'src/app/common/reusable/model/confirmdeletemodel/confirmdeletemodel.component';
import { ConfirmModelComponent } from 'src/app/common/reusable/model/confirmModel/model.component';
import { ResponseModelComponent } from 'src/app/common/reusable/model/confirmWithResponse/model.component';
import { confirmResponseModel } from 'src/app/common/reusable/model/confirmWithResponse/model.component.domain';
import { ConfirmModelYesOrNoComponent } from 'src/app/common/reusable/model/confirmYesOrNo/model.component';
import { DisplayModelComponent } from 'src/app/common/reusable/model/DisplayComponent/model.component';
import { HostmodelComponent } from 'src/app/common/reusable/model/hostmodel/hostmodel.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { DeleteModel } from "src/app/dto/DeleteModel";
import { MessageRecorded } from "src/app/dto/MessageRecorded";
import { ConfirmDeleteCarrier } from "src/app/dto/ConfirmDeleteCarrier";
import { MessagesToDelete } from "../../mymessages/inbox/MessagesToDelete";
import { ResponseFromUserModel } from "../../mymessages/inbox/ResponseFromUserModel";
import * as _ from 'lodash';
import { CommonFunc } from 'src/app/common/reusable/servicecenter/CommonFunc';
import { trigger, transition, style, animate } from '@angular/animations';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';

@Component({
  selector: 'app-express-interest-recieved-trash',
  templateUrl: './express-interest-recieved-trash.component.html',
  styleUrls: ['./express-interest-recieved-trash.component.scss'],
  providers: [MessageService, ConfirmationService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ]),

    trigger('fadeInOutFast', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ])

  ]
})
export class ExpressInterestRecievedTrashComponent {
  imageToDisplay: HostWindowModel;
  messageDataRecieved: MessageRecorded[];
  sortedDataRecieved: MessageRecorded[];
  selectedMessageItems: MessageRecorded[] = [];
  showLoader = false;
  messageDataSend: MessageRecorded[];
  sortedDataSend: MessageRecorded[];
  searchText: string = "";
  visibleColumns = [];
  userloggedIn: string;
  questiontoask: string;
  idToDelete: number[];
  cdcm: ConfirmDeleteCarrier;
  deleteModel: DeleteModel;
  responseFromUserModel: ResponseFromUserModel;
  selectAll: boolean;
  setting: {};
  selectionMode;
  userId: string;
  initialPage: Object;
  showExpressReceivedDelete: boolean = false;
  showExpressReceivedRestore: boolean = false;
  showExpressSendDelete: boolean = false;
  showExpressSendRestore: boolean = false;
  deleteSendModel: ConfirmDeleteCarrier;
  restoreSendModel: ConfirmDeleteCarrier;
  deletereceivedModel: ConfirmDeleteCarrier;
  restoreReceivedModel: ConfirmDeleteCarrier;
  @ViewChild(ConfirmModelComponent, { static: false }) modelWindow: ConfirmModelComponent;
  @ViewChild(DisplayModelComponent, { static: false }) displayWindow: DisplayModelComponent;
  @ViewChild(ResponseModelComponent, { static: false }) responsewindow: ResponseModelComponent;
  @ViewChild(ConfirmModelYesOrNoComponent, { static: false }) confirmyestornowindow: ConfirmModelYesOrNoComponent;
  @ViewChild(HostmodelComponent, { static: false }) imageModelWindow: HostmodelComponent;
  @ViewChild(ConfirmdeletemodelComponent, { static: false }) cmc: ConfirmdeletemodelComponent;
  showSearchIcon = true;
  showLargeImage = false;
  first = 0;
  rows = 10;
  totalRecords: number = 0;
  rowsPerPage: number = 10;
  pagedRecords: MessageRecorded[] = [];
  currentDeviceState: string = "";

  constructor(private commonService: Commonservice, private commonFunc: CommonFunc, private localStore: LocalStore, private datePipe: DatePipe, private messageService: MessageService, private confirmationService: ConfirmationService, private signalStateService: SignalStateService, private loaderService: GlobalLoaderService) { }

  ngOnInit() {
    const self = this;
    self.initiateInbox();
  }
  initiateInbox() {
    const self = this;

    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();  
    this.loaderService.loader$.subscribe(loader => { this.showLoader = loader; });
    self.messageDataRecieved = [];
    self.sortedDataRecieved = [];
    self.messageDataSend = [];
    self.sortedDataSend = [];

    self.questiontoask = 'Are you sure you want to delete?';
    self.idToDelete = [];
    self.deleteModel = {} as DeleteModel;
    self.selectAll = false;
    self.responseFromUserModel = {} as ResponseFromUserModel;
    self.selectionMode = { selectionMode: ['row'] };
    self.imageToDisplay = {} as HostWindowModel;
    self.cdcm = {} as ConfirmDeleteCarrier;
    self.cdcm.id = 0;
    self.cdcm.show = false;

    self.deleteSendModel = {} as ConfirmDeleteCarrier;
    self.deleteSendModel.id = 0;
    self.deleteSendModel.show = false;
    self.deleteSendModel.title = 'Are you sure, you want to delete send record?';

    self.restoreSendModel = {} as ConfirmDeleteCarrier;
    self.restoreSendModel.id = 0;
    self.restoreSendModel.show = false;
    self.restoreSendModel.title = 'Are you sure, you want to restore send record?';

    self.deletereceivedModel = {} as ConfirmDeleteCarrier;
    self.deletereceivedModel.id = 0;
    self.deletereceivedModel.show = false;
    self.deletereceivedModel.title = 'Are you sure, you want to delete received record?';

    self.restoreReceivedModel = {} as ConfirmDeleteCarrier;
    self.restoreReceivedModel.id = 0;
    self.restoreReceivedModel.show = false;
    self.restoreReceivedModel.title = 'Are you sure, you want to restore received record?';

    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.bindGrid();
    let columns = ["userToID", "UserFromName", "age", "height", "christaintype", "userExpressedDate", "messageStatus", "messageStatusChangeDate"];
    self.visibleColumns.push(...columns);
  }



  bindGrid() {
    const self = this;
    self.userloggedIn = self.userloggedIn;
    this.loaderService.showLoader();
    this.commonService.ExpressedInterestReceivedTrash(self.userloggedIn).subscribe(
      data => {
        this.loaderService.hideLoader();
        self.runExpressReceived(data);
      },
      error => { }
    );
  }
  runExpressReceived(data: any) {
    const self = this;
    self.messageDataRecieved = [];
    self.sortedDataRecieved = [];
    if (data !== undefined) {
      if (data?.length > 0) {
        _.forEach(data, function (value, key) {
          let myData: MessageRecorded;
          myData = {} as MessageRecorded;
          myData = value;
          if (value.messageStatusChangeDate !== null) {
            value.messageStatusChangeDate = self.datePipe.transform(Date.now(), "yyyy-MM-dd");
          }
          else {
            value.messageStatusChangeDate = "Not yet";
          }

          if (value.messageStatus === null) {
            value.messageStatus = 'Not seen';
          }

          myData.pathToProfile = "./../viewotherprofile";
          myData.showIconEngine = true;
          self.messageDataRecieved.push(myData);
          self.sortedDataRecieved.push(myData);
          self.totalRecords = self.sortedDataRecieved.length;
          self.loadPageData(0, self.rowsPerPage);
        });
        self.initialPage = { pageSizes: true, pageCount: 4 };
      }
      else {
        self.messageDataRecieved = [];
        self.sortedDataRecieved = [];
        self.totalRecords = 0;
        self.pagedRecords = [];
      }

    }
  }

  loadPageData(page: number, rows: number) {
    const start = page * rows;
    const end = start + rows;
    this.pagedRecords = this.sortedDataRecieved.slice(start, end);
  }

  onPageChange(event: PageEvent) {
    this.first = event.first; 
    this.rows = event.rows; 
    const page = Math.floor(this.first / this.rows);
    this.loadPageData(page, this.rows);
  }

  selectedThemAll() {
  }

  viewMessage() {
    const self = this;
  }

  openReceivedDeleteModal(event: Event, item) {
    const self = this;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let deleteId = {} as DeleteModel;
        deleteId.sqlWhere = item.Id.toString();
        this.loaderService.showLoader();
        self.commonService.deleteExpressInterestReceivedTrash(deleteId).subscribe(
          data => {
            this.loaderService.hideLoader();
            self.bindGrid();
          }
        )
      },
      reject: () => {
        //reject action
      }
    });
  }

  openReceiveRestoreModel(event: Event, item) {
    const self = this;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let deleteId = {} as DeleteModel;
        deleteId.sqlWhere = item.Id.toString();
        this.loaderService.showLoader();        
        self.commonService.restoreExpressInterestReceived(deleteId).subscribe(
          data => {
            this.loaderService.hideLoader();
            self.bindGrid();
          }
        );
      },
      reject: () => {
        //reject action
      }
    });
  }

  openSendDeleteModal(event: Event, item) {
    const self = this;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let deleteId = {} as DeleteModel;
        deleteId.sqlWhere = item.Id.toString();
        this.loaderService.showLoader();
        self.commonService.deleteExpressInterestReceivedTrash(deleteId).subscribe(
          data => {
            this.loaderService.hideLoader();
            self.bindGrid();
          }
        );
      },
      reject: () => {
        //reject action
      }
    });
  }

  openSendRestoreModal(event: Event, item) {
    const self = this;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let deleteId = {} as DeleteModel;
        deleteId.sqlWhere = item.Id.toString();
        this.loaderService.showLoader();
        self.commonService.restoreExpressInterestSent(deleteId).subscribe(
          data => {
            this.loaderService.hideLoader();
            self.bindGrid();
          }
        );
      },
      reject: () => {
        //reject action
      }
    });
  }

  closeReceivedTrash() {
    const self = this;
    self.deletereceivedModel = {} as ConfirmDeleteCarrier;
    self.deletereceivedModel.show = false;
  }

  closeRestoreReceived() {
    const self = this;
    self.restoreReceivedModel = {} as ConfirmDeleteCarrier;
    self.restoreReceivedModel.show = false;
  }

  closeDeleteSend() {
    const self = this;
    self.deleteSendModel = {} as ConfirmDeleteCarrier;
    self.deleteSendModel.show = false;
  }
  closeRestoreSend() {
    const self = this;
    self.restoreSendModel = {} as ConfirmDeleteCarrier;
    self.restoreSendModel.show = false;
  }

  deleteRecievedExpression(event) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = event.id.toString();
    this.loaderService.showLoader();
    self.commonService.deleteExpressInterestReceivedTrash(deleteId).subscribe(
      data => {
        this.loaderService.hideLoader();
        self.deletereceivedModel.show = false;
        self.bindGrid();
      }
    );
  }

  deleteExpressSend(event) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = event.id.toString();
    self.commonService.deleteExpressInterestSentTrash(deleteId).subscribe(
      data => {
        this.loaderService.hideLoader();
        self.bindGrid();
        self.deleteSendModel.show = false;
      }
    );
  }

  restoreExpressInterestSent(event) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = event.id.toString();
    this.loaderService.showLoader();
    self.commonService.restoreExpressInterestSent(deleteId).subscribe(
      data => {
        this.loaderService.hideLoader();
        self.bindGrid();
        self.restoreSendModel.show = false;
      }
    );
  }

  restoreExpressInterestReceived(event) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = event.id.toString();
    this.loaderService.showLoader();
    self.commonService.restoreExpressInterestReceived(deleteId).subscribe(
      data => {
        this.loaderService.hideLoader();
        self.bindGrid();
        self.restoreReceivedModel.show = false;
      }
    );
  }

  runNow() {
    const self = this;
    self.selectAll = false;
    self.bindGrid();
  }
  confirmDelete(event: number[]) {
    const self = this;
    let idsToDelete = '';
    idsToDelete = event.toString();
    self.deleteModel.sqlWhere = idsToDelete;
    this.loaderService.showLoader();
    this.commonService.deleteSelectedMessages(self.deleteModel).subscribe(
      () => {
        this.loaderService.hideLoader();
        self.runNow();
      },
      error => { }
    );
  }

  replyMessage(item) {
    const self = this;
    self.responsewindow.show(item.interestFromUserId);
  }

  responseToMessage(event: confirmResponseModel) {
    const self = this;
    self.responseFromUserModel.userOnlineFromWhomMessageIsBeingSend = self.userloggedIn;
    self.responseFromUserModel.user_To = event.id.toString();
    self.responseFromUserModel.user_ToID = event.reponse.toString();
    self.responseFromUserModel.user_ExpressedDate = new Date();
    self.responseFromUserModel.status = '1';
    self.responseFromUserModel.theTextBeingSend = event.reponse;
    this.loaderService.showLoader();
    self.commonService.responseMessageFromUser(self.responseFromUserModel).subscribe(
      () => {
        this.loaderService.hideLoader();
        self.runNowResponseMessage();
      },
      error => { }
    );
  }

  openMultiDeleteModal() {
    const self = this;
    let idsToDelete: MessagesToDelete = {} as MessagesToDelete;
    idsToDelete.Ids = [];

    let member = self.selectedMessageItems.length > 1 ? "members" : "member";

    if (self.selectedMessageItems.length > 0) {
      self.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure that you want to delete selected ' + member + ' proposals?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          self.selectedMessageItems.forEach(element => {
            idsToDelete.Ids.push(element.Id);
          });
          this.loaderService.showLoader();
          self.commonService.deleteTrashMessageReceivedMultiple(idsToDelete).subscribe(
            data => {
              this.loaderService.hideLoader();
              if (data.DeleteWorked) {
                this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
              }
              else {
                this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
              }
              self.bindGrid();
            }
          );
        },
        reject: () => {
          //reject action
        }
      });
    }
    else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "please select at least one item to delete" });
    }
  }

  runNowResponseMessage() {
    const self = this;
    self.bindGrid();
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (imageThumbnailId != null) {
      this.loaderService.showLoader();
      self.commonService.getLargeImage(imageThumbnailId).subscribe(
        data => {
          self.showLargeImage = true;
          this.loaderService.hideLoader();
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => { }
      );
    }
    else {
    }
  }

  globalSearch(event) {
    const self = this;
    let columnsToFilter = self.visibleColumns;
    self.sortedDataRecieved = self.commonFunc.searchText(self.messageDataRecieved, event, columnsToFilter);
  }

  setShowIcon(item: MessageRecorded) {
    item.showIconEngine = !item.showIconEngine;
  }

}
