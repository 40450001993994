<!-- The Modal -->
<div id="myModalConfirmModel" class="modal">

  <!-- Modal content -->
  <div class="modal-content">
    <span class="close" (click)="closewindow()">&times;</span>
    <div class="row">
      <div class="col-md-12 text-center">
        <p>{{questionToAsk}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-center">
        <button (click)=CallParentMethod(deleteId) pButton color="primary"> Yes</button>
      </div>
      <div class="col-md-6">
        <button (click)=closewindow() pButton>No</button>
      </div>
    </div>
  </div>

</div>