import { RegisteredUser } from './view.components.domain';
import { Injectable } from '@angular/core';


@Injectable()
export class ViewInternalService {
  public registeredUser: RegisteredUser;
  constructor() {
  }
  ngOnInit() {
    const self = this;
    self.registeredUser = {} as RegisteredUser;
  }
  setUserInfo(userInfo: RegisteredUser) {
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  }
  getUserInfo() {
    const obj = sessionStorage['userInfo'];
    return obj;
  }
}
