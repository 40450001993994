import { confirmResponseModel } from 'src/app/common/reusable/model/confirmWithResponse/model.component.domain';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { W3ModelContent } from "../../../../dto/W3ModelContent";

@Component({
  selector: 'w3modelwithresponse',
  templateUrl: './w3modelwithresponse.component.html',
  styleUrls: ['./w3modelwithresponse.component.sass']
})
export class W3modelwithresponseComponent implements OnInit {
  @Output() confirmaction = new EventEmitter<confirmResponseModel>();
  //  modelcontent: confirmBoolResponseModel;
  @Input() mc: W3ModelContent;
  constructor() {


  }

  ngOnInit() {
    this.mc = {} as W3ModelContent;
    this.mc.show = false;
    this.mc.showHeader = false;
    this.mc.showFooter = false;
  }

  sendMessage(passedValue: string, response: string) {
    const self = this;
    const crm = {} as confirmResponseModel;
    crm.id = passedValue;
    crm.reponse = response;
    self.confirmaction.next(crm);
    self.hideModel();
  }
  
  hideModel() {
    this.mc.show = false;
  }
  cancelWindow() {
    this.mc.show = false;
  }
 

}
