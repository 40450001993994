import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'displaywindow',
    templateUrl: './model.component.html',
    styleUrls: ['./model.component.css']
})
export class DisplayModelComponent implements OnInit {
    @Input() dataToDisplay;
    @Input() deleteId;
    @Output() confirmaction = new EventEmitter<number>();
    dataBeingDisplayed: string;
    ngOnInit(): void {
        const self = this;
        self.dataBeingDisplayed = this.dataToDisplay;
    }

    show() {
        const modal = document.getElementById('myModalDisplayComponents');
        modal.style.display = 'block';
    }

    closewindow() {
        const modal = document.getElementById('myModalDisplayComponents');
        modal.style.display = 'none';
    }
}
