import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ViewExternalService {
  connect = environment.apiUrl;
  constructor(_router: Router, private httpClient: HttpClient) { }
  getOtherUserProfile(nameOfTheOneBeingVisited, userLoggedIn) {
    const theresult = this.httpClient.get(this.connect + '/HomePage/GetOtherUserProfile/' + nameOfTheOneBeingVisited + '/' + userLoggedIn);
    return theresult;
  }
}


