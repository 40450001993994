<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Expected Education Category</div>
  </div>
  <div class="col-4">
    <p-multiSelect [options]="uic.PartnerExpectedEducation" placeholder="Partner Expected Education Categories  "
      [(ngModel)]="expectedPartnerEducationSelected" optionLabel="Name" [maxSelectedLabels]="5"
      [selectedItemsLabel]="'{0} items selected'" [style]="{ width: '100%' }"
      [panelStyle]="{ width: '100%' }"></p-multiSelect>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Expected Employment Category</div>
  </div>
  <div class="col-4">
    <p-multiSelect [options]="uic.PartnerExpectedEmployement" placeholder="Partner Expected Employment Categories  "
      [(ngModel)]="PartnetExpectedEmploymentCategorySelected" [maxSelectedLabels]="5"
      [selectedItemsLabel]="'{0} items selected'" optionLabel="Name" [style]="{ width: '100%' }"
      [panelStyle]="{ width: '100%' }"></p-multiSelect>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Religion Denominations Considered</div>
  </div>
  <div class="col-4">
    <p-multiSelect [options]="uic.PartnersReligionDenominationConsidered"
    placeholder="Partners Religion Denomination Considered"
      [(ngModel)]="partnerReligionDenominationsConsideredSelected" [maxSelectedLabels]="5"
      [selectedItemsLabel]="'{0} items selected'" optionLabel="Name" [style]="{ width: '100%' }"
      [panelStyle]="{ width: '100%' }"></p-multiSelect>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Consider Second Marriage</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.PartnerConsiderSecondMarriage" [(ngModel)]="considerSecondMarriageSelected"
      optionLabel="Name" [style]="{ width: '100%', overflow: 'visible' }"></p-dropdown>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Family Status</div>
  </div>
  <div class="col-4">
    <p-multiSelect [options]="uic.FamilyStatus"
    placeholder="Family Status"
    [(ngModel)]="familyStatusSelected" [maxSelectedLabels]="5"
    [selectedItemsLabel]="'{0} items selected'" optionLabel="Name" [style]="{ width: '100%' }"
    [panelStyle]="{ width: '100%' }"></p-multiSelect>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Marital Status</div>
  </div>
  <div class="col-4">
    <p-multiSelect [options]="uic.MaritalStatus"
    placeholder="Marital Status"
    [(ngModel)]="martialStatusSelected" [maxSelectedLabels]="5"
    [selectedItemsLabel]="'{0} items selected'" optionLabel="Name" [style]="{ width: '100%' }"
    [panelStyle]="{ width: '100%' }"></p-multiSelect>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Physical Status</div>
  </div>
  <div class="col-4">
    <p-multiSelect [options]="uic.PhysicalStatus"
    placeholder="Physical Status"
    [(ngModel)]="physicalStatusSelected" [maxSelectedLabels]="5"
    [selectedItemsLabel]="'{0} items selected'" optionLabel="Name" [style]="{ width: '100%' }"
    [panelStyle]="{ width: '100%' }"></p-multiSelect>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Brief Narration About Your Expectations</div>
  </div>
  <div class="col-4">
    <textarea rows="5" cols="30" pInputTextarea placeholder="Brief Narration About Your Expectations"
      [(ngModel)]="GetEditProfilePartner.Partner_Expectations_brief" style="width: 100%"></textarea>
  </div>
</div>
<div class="flex py-1">
  <div class="col-10"></div>
  <div class="col-2 text-right">
    <button pButton (click)="saveInformation()">SAVE</button>
  </div>
</div>

<p-toast position="bottom-right"></p-toast>