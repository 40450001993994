import { Routes } from '@angular/router';
import { GaurdService } from '../../../common/reusable/servicecenter/gaurdService.component';
import { PrivatesearchbyprofileComponent } from './privatesearchbyprofile.component';



// Route Configuration
export const SearchByProfileRoute: Routes = [
  { path: 'searchbyprofile/:id', canActivate: [GaurdService], component: PrivatesearchbyprofileComponent }
];
