import { EditProfileDto } from "../../../dto/EditProfileDto";
import { Component, computed, OnInit } from '@angular/core';
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { RegisteredUser } from '../view/view.components.domain';
import * as _ from 'lodash';
import { LoaderService } from "src/app/common/reusable/servicecenter/LoaderService";
import { SignalBasedService } from "src/app/common/reusable/servicecenter/SignalBasedService";

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss']
})
export class EditprofileComponent implements OnInit {
  ui: RegisteredUser;
  uic: EditPageMegaBucket;
  epd: EditProfileDto;
  loadChild = false;
  userloggedIn: string;
  isLoading = this.signalBasedService.getIsLoading();

  constructor(private localStore: LocalStore, public loadingService: LoaderService, private signalBasedService: SignalBasedService) {

  }

  ngOnInit(): void {
    const self = this;

    self.ui = {} as RegisteredUser;
    self.uic = {} as EditPageMegaBucket;
    self.epd = {} as EditProfileDto;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.bindData();
  }

  bindData() {
    const self = this;
    this.loadingService.startLoading();
    this.signalBasedService.fetchData(self.userloggedIn).subscribe(
      () => {
        self.loadChild = true;
        self.uic = computed(() => this.signalBasedService.getApiData()())();
        console.log('Data fetched successfully')
        this.loadingService.stopLoading();
      },
      error => console.error('Error fetching data:', error)
    );
  }
}
