<publicpage>
<div class="row">
  <div class="col-md-12">
    <div>
      <div class="aboutus-title">About Nekshathram.com</div><br>
      <div class="aboutus-content">It is a Premier Christian matrimonial. All are welcome to join it. We have a
        subscription fees, I personally think, it is quite affordable. If any one of you thinks are you cannot afford
        the pricing and you need a subscription, please do mail me with proof stating your reasons. I will consider the
        request.<br><br>Nekshathram.com is hosted in Windows Azure making it, highly available. It has its services
        running also from Amazon Web services, which makes it totally cloud based solution. With high availability you
        can be sure, that your friendly Nekshathram.com is available 99.99% of time. The other time, we just cannot
        help.<br><br>Nekshathram.com uses state of art mailing system called SendGrid which makes sure that your
        profile mail is least likely to end up in the junk mail or spam folder. When we chose SendGrid we had these
        points in mind for the customers who used out site:<br>1. Avoid relegation to the spam folder and rejection by
        mail servers.<br>2. Handle large volumes.<br>3. Analytics &amp; Transparency: Track email performance, reads,
        etc. and verify whether emails have reached their destination.<br>4. Opt Outs: Ability to prevent mailing to
        certain addresses in a separate layer, rather than relying on applications. I know few points I said above
        would have gone above your head. To me it just means it delivers well, I guess. Hopefully it does what it says
        it does. Economical: Nekshathram.com has tried to keep the cost down and service up. The customer gets less
        load when it comes to subscription.<br>We have one month subscription model which no other matrimonial service
        offers. We do not offer phone support right now, but we do reply to the mails send to us as soon as possible.
        Nekshathram.com was made with thoughts of the people who require such service with affordable pricing and good
        service. We believe that, the customer will be a winner, when using Nekshathram.com.<br><br>We try to send out
        offers through social networking sites like Twitter, Facebook, Pinterest and YouTube. Stay Tuned. Thanks to all
        who support and help us grow in the market. Thanks &amp; Regards,</div>
      <div class="aboutus-ceo">CEO</div>
    </div>
  </div>
</div>

</publicpage>