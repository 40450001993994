<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>
<ng-containter *ngIf="!showLoader">
  <div class="grid align-items-center style-action">
    <!-- Image Section -->
    <div class="col-12 md:col-2 text-left">
      <img [src]="userInformation?.userImageReal" alt="..loading" class="profile-image-no-cursor img-thumbnail" />
    </div>

    <!-- Divider (visible only on medium and larger screens) -->
    <div class="col-12 md:col-1 hidden sm:block">
      <p-divider layout="vertical"></p-divider>
    </div>

    <!-- Information Section -->
    <div class="col-12 md:col-9">
      <div class="grid">
        <div class="col-12 sm:col-3 py-2 font-semibold">Name:</div>
        <div class="col-12 sm:col-9 py-2">{{ userInformation?.name }}</div>

        <div class="col-12 sm:col-3 py-2 font-semibold">Profile:</div>
        <div class="col-12 sm:col-9 py-2">{{ userInformation?.username }}</div>

        <div class="col-12 sm:col-3 py-2 font-semibold">Gender:</div>
        <div class="col-12 sm:col-9 py-2">{{ userInformation?.Gender }}</div>

        <div class="col-12 sm:col-3 py-2 font-semibold">Registered Date:</div>
        <div class="col-12 sm:col-9 py-2">{{ userInformation?.registeredDate | date }}</div>

        <div class="col-12 sm:col-3 py-2 font-semibold">Membership Type:</div>
        <div class="col-12 sm:col-9 py-2">{{ userInformation?.membershipType }}</div>

        <div class="col-12 sm:col-3 py-2 font-semibold">Renewal Date:</div>
        <div class="col-12 sm:col-9 py-2">{{ userInformation?.renewalDate | date }}</div>
      </div>
    </div>
  </div>

  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>

  <p-toast position="bottom-right"></p-toast>
  <p-confirmPopup></p-confirmPopup>
</ng-containter>