import { Component } from '@angular/core';

@Component({
  selector: 'container-loader',
  templateUrl: './containter-loader.component.html',
  styleUrls: ['./containter-loader.component.css']
})
export class ContainterLoaderComponent {

}
