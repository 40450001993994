<p-divider></p-divider>
<p-card>
  <div class="menu-bar-1">
    <div class='flex py-1'>
      <div class='col-10'>
        "Love is the eternal melody that resonates within our souls" - Anonymous
      </div>
      <div class='col-2'>
        <div class='flex py-1'>
          <div class='col-4'>
            <img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
              src="../../../../assets/image/newicons/love-message-4669.png" width="30" height="26"
              style="cursor: pointer;" alt="" (click)="expressInterestPop($event)" />
          </div>
          <div class='col-4'>
            <img pTooltip="SEND MESSAGE" tooltipPosition="top" src="../../../../assets/image/newicons/envelope.png"
              class="rc-ito-3" alt="" (click)="showSendDialogue()" />
          </div>
          <div class='col-4'>
            <img src="../../../../../../assets/image/newicons/bookmark.png" pTooltip="BOOKMARK" tooltipPosition="top"
              width="30" height="26" style="cursor: pointer;" (click)="bookmarkprofile()">
          </div>
        </div>
      </div>
    </div>
  </div>
</p-card>
<p-divider></p-divider>

<p-toast position="bottom-right"></p-toast>
<p-confirmPopup></p-confirmPopup>
<p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
  <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
    (OnSubmit)="eventPostSubmit($event)"></send-message>
</p-dialog>