<div class="barelist top-list">

  <div class="my-background-top-bar">

    <div class="grid">
      <div class="col-11"></div>
      <div class="col-1 text-right">
        <div style="display: flex;flex-direction: row; justify-content:end;">
          <div style="color: white;
          padding-left: 14px;
          padding-top: 8px;
          font-weight: 900;
          margin-right: 20px;
          text-transform: uppercase;">
            {{ username }}
          </div>
          <div> <i class="pi pi-power-off"
              style="font-size: 1rem; padding-top: 10px; color: white; cursor: pointer; font-weight: 900;"
              (click)="logOut()"></i></div>
        </div>
      </div>
    </div>

  </div>
  <div class="menu-container">
    <div class="topnav" id="myTopnav">
      <ng-container *ngFor="let item of menuArray; let i = index">
        <a [routerLink]="[item.Path]" [routerLinkActive]="['active']" (contextmenu)="disableRightClick($event)">{{
          item.Name }}
        </a>
      </ng-container>
      <a href="javascript:void(0);" class="icon" (click)="myFunction()">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </a>
    </div>
  </div>
</div>