import { Component, Input,  OnInit } from '@angular/core';
import { Commonservice } from '../servicecenter/commonservice.component';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() hide = true;
  
  constructor(private commonservice: Commonservice) { }

  ngOnInit() {
    const self = this;
    self.commonservice.newSubscribedObject.subscribe(
      data => {
        self.hide = data;
      }
    );
  }

}
