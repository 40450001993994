import { Routes } from '@angular/router';
import { ModifypasswordComponent } from './modifypassword.component';
import { GaurdService } from '../../../common/reusable/servicecenter/gaurdService.component';



// Route Configuration
export const mPRoute: Routes = [
  { path: 'modifypassword', canActivate: [GaurdService], component: ModifypasswordComponent }
];
