<!-- <ng-containter *ngIf="showImageLoader">
  <loader></loader>
</ng-containter> -->

<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>

<p-fieldset legend="BOOKMARKS">
  <div>
    <div [ngClass]="bookMarkedUsers?.length>0 ? 'card-container' : 'card-container-without-padding'">
      <p-card *ngFor="let item of pagedItems" class="card">
        <ng-template pTemplate="header">
          <img [src]="item.BookmarkedGuyImage" [ngClass]="[
          item.BookmarkedGuyImage
            ? 'bookmark-image img-thumbnail'
            : 'bookmark-image-no-cursor'
        ]" (click)="
          makeitbig(                  
            item.BookmarkedPersonsImage,
            item.userWhoGotBookMarked
          )
        " [@fadeScaleAnimation]="bookMarkedUsers" />
        </ng-template>
        <ng-template pTemplate="body">
          <div class="col bookmark-text">
            <div class="hl">
              <div class="flex flex-column gap-1 text-block">
                <div class="flex flex-row flex-wrap">
                  <div class="col-5"><span class="font-semibold"> Name </span></div>
                  <div class="col-7 book-mark-name">{{item.NameOfPersonWhoGotBookmarked }}</div>
                </div>
                <div class="flex flex-row flex-wrap">
                  <div class="col-5"><span class="font-semibold"> ID </span></div>
                  <div class="col-7 book-mark-id">{{item.userWhoGotBookMarked }}</div>
                </div>
                <div class="flex flex-row flex-wrap">
                  <div class="col-5"><span class="font-semibold"> Date </span></div>
                  <div class="col-7 book-mark-id">{{ item.dateBookMarked | date }}</div>
                </div>
              </div>
              <div class="hl-block-2">
                <div class="image-grid">
                  <div *ngIf="item?.showIconEngine">
                    <i class="pi pi-cog settings-icon" (click)="setShowIcon(item)"></i>
                  </div>
                  <div *ngIf="!item.showIconEngine" class="icon-set" @fadeScaleAnimation2>
                    <div class="image-block"><img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
                        src="../../../../assets/image/newicons/love-message-4669.png" class="brc-ito-3" alt=""
                        (click)="expressInterestPop($event, item)" /></div>
                    <div class="image-block"> <img pTooltip="SEND MESSAGE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/envelope.png" class="brc-ito-2" alt=""
                        (click)="showSendDialogue(item)" /></div>
                    <div class="image-block"> <img pTooltip="VIEW PROFILE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/id-card.png" class="brc-ito-1" alt=""
                        (click)="navigateToDetails(item)" /></div>
                    <div class="image-block"> <img pTooltip="DELETE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/delete-10399.png" class="brc-ito-4" alt=""
                        (click)="removeBookmark($event, item)" /></div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </ng-template>
      </p-card>
    </div>





  </div>
  <div style="margin-top: 10px; text-align: center" *ngIf="bookMarkedUsers?.length>0">
    <!-- <pagination-controls id="bookmark" (pageChange)="onPageChange($event)"></pagination-controls> -->

    <p-paginator [rows]="rows" [totalRecords]="totalRecords" (onPageChange)="paginate($event)">
    </p-paginator>

    <!-- <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
      [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true" [showJumpToPageDropdown]="false"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" /> -->
  </div>





  <div *ngIf="bookMarkedUsers?.length === 0 && !showLoader" class="no-value-text">
    No Bookmarks yet
  </div>

  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>
  <p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
    <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
      (OnSubmit)="eventPostSubmit($event)"></send-message>
  </p-dialog>

  <p-confirmPopup></p-confirmPopup>
  <p-toast position="bottom-right"></p-toast>





</p-fieldset>