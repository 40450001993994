import { SignalRService } from "./SignalRService";

export function initializeSignalR(signalRService: SignalRService) {
    return () => {
      return new Promise<void>((resolve) => {
        signalRService.startConnection();
        signalRService.getConnectionState().subscribe(state => {
          console.log('SignalR connection state:', state);
          if (state === 'Connected') {
            resolve();
          }
        });
      });
    };
  }