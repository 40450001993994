import { ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { Dropdown } from "src/app/dto/Dropdown";
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { SaveResponse } from "src/app/dto/SaveResponse";
import { GetEditProfileContactDto } from "src/app/dto/GetEditProfileContactDto";
import * as _ from 'lodash';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { MessageService } from 'primeng/api';
import { finalize, forkJoin, map, switchMap } from 'rxjs';
import { LoaderService } from 'src/app/common/reusable/servicecenter/LoaderService';
import { SignalBasedService } from 'src/app/common/reusable/servicecenter/SignalBasedService';

@Component({
  selector: 'edit-postal-address',
  templateUrl: './edit-postal-address.component.html',
  styleUrls: ['./edit-postal-address.component.css'],
  providers: [MessageService]
})
export class EditContactProfileComponent implements OnInit {
  @ViewChild('cityDropdown') cityDropdown: Dropdown;
  @Input() epd: EditPageMegaBucket = {} as EditPageMegaBucket;
  ui: GetEditProfileContactDto;
  uic: EditPageMegaBucket;
  postalRegionSelected: Dropdown;
  cityOrDistrictSelected: Dropdown;
  srm: SaveResponse;
  userloggedIn: string;
  showCity = false;
  showState = false;
  cities: Dropdown[] = [];
  states: Dropdown[] = [];
  countries: Dropdown[] = [];
  postalRegion: Dropdown[] = [];
  countrySelectedValue: Dropdown;
  citySelectedValue: Dropdown;
  stateSelectedValue: Dropdown;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, public loadingService: LoaderService, private signalBasedService: SignalBasedService) { }

  ngOnInit(): void {
    const self = this;
    self.ui = {} as GetEditProfileContactDto;
    self.uic = {} as EditPageMegaBucket;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.uic = self.epd;
      self.getDataFromDb(result.UserName);
    });
  }

  getDataFromDb(userLoggedIn: string) {
    const self = this;
    const ccn = self.commonService;
    this.loadingService.startLoading();
    ccn.getEditProfileContact(userLoggedIn).subscribe(postalAddress => {
      this.loadingService.stopLoading();
      self.countries = self.uic.Countries;
      self.states = self.uic.PostalStates;
      self.cities = self.uic.PostalCities;
      self.postalRegion = self.uic.PostalRegion;
      self.processCountries(postalAddress);
      self.processStates(self.uic.PostalStates);
      self.processCities(self.uic.PostalCities);
      this.loadingService.stopLoading();
    });
  }

  processCountries(s: GetEditProfileContactDto) {
    const self = this;
    self.ui = s;
    if (self.ui.Message_Country != undefined) {
      let countrySelectedValueFromDb = self.uic.Countries.filter(x => x.Value.toString() === self.ui.Message_Country)[0];
      self.countrySelectedValue = self.ui.Message_Country === undefined ? self.uic.Countries[0] : countrySelectedValueFromDb;
    }
    else {
      self.countrySelectedValue = self.uic.Countries.filter(x => x.Value === 0)[0];
    }
    this.bindData();
  }

  processStates(x: Dropdown[]) {
    const self = this;
    if (x?.length > 0) {
      self.showState = true;
      if (self.ui.Message_State != undefined) {
        let stateSelectedValueFromDb = self.uic.PostalStates.filter(x => x.Value.toString() === self.ui.Message_State.toString())[0];
        self.stateSelectedValue = self.ui.Message_State === undefined ? self.uic.PostalStates[0] : stateSelectedValueFromDb;
      }
      else {
        self.stateSelectedValue = self.uic.States.filter(x => x.Value.toString() === '0')[0];
      }
    }
    else {
      self.showState = false;
    }
  }

  processCities(x: Dropdown[]) {
    const self = this;
    if (x.length > 0) {
      if (self.ui.Message_City != undefined) {
        let citySelectedValueFromDb = self.uic.PostalCities.filter(x => x.Value.toString() === self.ui.Message_City)[0];
        self.citySelectedValue = self.ui.Message_City === undefined ? self.uic.PostalCities[0] : citySelectedValueFromDb;
      }
      else {
        self.citySelectedValue = self.uic.Cities.filter(x => x.Value === 0)[0];
      }
      self.showCity = true;
    }
    else {
      self.showCity = false;
    }
  }

  bindData() {
    const self = this;
    if (self.uic.PostalRegion.find(x => x.Value.toString() == self.ui.Message_PostalRegion) != undefined) {
      self.postalRegionSelected = self.uic.PostalRegion.find(x => x.Value.toString() == self.ui.Message_PostalRegion);
    }
    else {
      self.postalRegionSelected = _.first(self.uic.PostalRegion).value;
    }
  }

  onCountryChange(event) {
    const self = this;
    const ccn = self.commonService;
    if (event.value != null) {
      self.countrySelectedValue = event.value;
      ccn.getStatesBasedOnCountry(self.countrySelectedValue.Value.toString()).subscribe(x => {
        if (x?.length > 0) {
          self.showState = true;
          self.states = [];
          self.states.push(...x);
          self.stateSelectedValue = undefined;
        }
        else {
          self.showState = false;
        }
      });
    }
    else {
      self.stateSelectedValue = { Name: "Select", Value: 0 };
      self.citySelectedValue = { Name: "Select", Value: 0 };
      self.showState = false;
      self.showCity = false;
    }
  }

  onStateChange(event) {
    const self = this;
    const ccn = self.commonService;
    if (event.value != null) {
      self.stateSelectedValue = event.value;
      if (self.stateSelectedValue != undefined) {
        ccn.getCitiesBasedOnState(self.stateSelectedValue.Value.toString(), self.countrySelectedValue.Value.toString()).subscribe(x => {
          self.cities = [];
          if (x.length > 0) {
            self.cities = [];
            self.cities.push(...x);
            self.citySelectedValue = undefined
            self.showCity = true;
          }
          else {
            self.showCity = false;
          }
        });
      }
    }
    else {
      self.citySelectedValue = { Name: "Select", Value: 0 };
      self.showCity = false;
    }
  }

  saveInformation() {
    const self = this;
    this.loadingService.startLoading();
    let setEditProfileJobDto: GetEditProfileContactDto = {} as GetEditProfileContactDto;
    setEditProfileJobDto.Message_Address = self.ui.Message_Address;
    setEditProfileJobDto.Message_Pincode = self.ui.Message_Pincode;
    setEditProfileJobDto.Message_PostalRegion = self.postalRegionSelected.Value.toString();
    setEditProfileJobDto.Message_City = self.citySelectedValue.Value.toString();
    setEditProfileJobDto.Message_State = self.stateSelectedValue.Value.toString();
    setEditProfileJobDto.Message_Country = self.countrySelectedValue.Value.toString();
    setEditProfileJobDto.Message_ContactNo = self.ui.Message_ContactNo;
    setEditProfileJobDto.Message_MobileNumber = self.ui.Message_MobileNumber;
    setEditProfileJobDto.Candidate_Username = self.userloggedIn;
    this.commonService.setEditContactDetails(setEditProfileJobDto).subscribe(
      data => {
        self.srm = data;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.srm.Message });
        this.signalBasedService.fetchData(self.userloggedIn).subscribe(
          () => console.log('Data fetched successfully'),
          error => console.error('Error fetching data:', error)
        );
      },
      error => { }
    );

  }
}
