<form #changePassword="ngForm" (ngSubmit)="onSubmit(changePassword)">
    <div class="barelist standard-font tp-wrapper">
        <div>
            <div class="barelist login-port">
                <div>
                    <div class="grid">
                        <div class="col-4 sub-title text-center">New password</div>
                        <div class="col-8 p-r-20">
                            <input pInputText type="password" name="password1" placeholder="Password"
                                [(ngModel)]="password1" style="width: 100%;" required
                                (input)="validatePasswordMatch()" />
                        </div>
                    </div>
                </div>
                <div class="p-t-5">
                    <div class="grid">
                        <div class="col-4 sub-title text-center">Confirm password</div>
                        <div class="col-8 p-r-20">
                            <input pInputText type="password" name="password2" placeholder="Confirm Password"
                                [(ngModel)]="password2" style="width: 100%;" required
                                (input)="validatePasswordMatch()" />
                        </div>
                    </div>
                </div>
                <div class="p-t-5">
                    <div class="grid">
                        <div class="col-4"></div>
                        <div class="col-8">
                            <div class="grid">
                                <div class="col-3">
                                    <button enable="disableSubmit" pButton type="submit" color="primary"
                                        class="btn-login" [disabled]="!password1 || !password2  || !isValidToken">
                                        SUBMIT
                                    </button>
                                </div>
                                <div class="col-2">
                                    <div *ngIf="passwordMismatchError" class="error-message">
                                        {{ passwordMismatchError }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="parent-div">
                <img src="../../../../assets/image/couple.jpg" alt="" class="image">

            </div>
        </div>
    </div>
</form>
<p-toast position="bottom-right"></p-toast>