<page>
  <ng-template>
    <form #changePassword="ngForm" (ngSubmit)="onSubmit(changePassword)" style="width: 100%;">
      <div style="width: 100%;">

        <div class="super-title">

          RESET PASSWORD

        </div>
        <p-divider />
        <p-card style="width: 100%;">
          <div class="flex flex-wrap py-1" style="width: 100%;">
            <div class="col-12 sm:col-6 md:col-4 lg:col-4">NEW PASSWORD</div>
            <div class="col-12 sm:col-6 md:col-4 lg:col-4">
              <input pInputText type="password" name="password1" placeholder="Password" [(ngModel)]="password1"
                style="width: 100%;" required (input)="validatePasswordMatch()" />
            </div>
            <div class="col-12 sm:col-6 md:col-4 lg:col-4"></div>
          </div>
          <div class="flex flex-wrap py-1">
            <div class="col-12 sm:col-6 md:col-4 lg:col-4">CONFIRM PASSWORD</div>
            <div class="col-12 sm:col-6 md:col-4 lg:col-4">
              <input pInputText type="password" name="password2" placeholder="Confirm Password" [(ngModel)]="password2"
                style="width: 100%;" required (input)="validatePasswordMatch()" />
            </div>
            <div class="col-12 sm:col-6 md:col-4 lg:col-4"></div>
          </div>
          <div class="flex flex-wrap py-1">
            <div class="col-12 sm:col-6 md:col-4 lg:col-4">

            </div>


            <div class="col-12 sm:col-6 md:col-4 lg:col-1">
              <button enable="disableSubmit" class="custom-button" pButton type="submit" color="primary"
                [disabled]="!password1 || !password2">
                SUBMIT
              </button>
            </div>
            <div class="col-12 sm:col-6 md:col-4 lg:col-1 pt-2">
              <button type="button" class="custom-reset-button" enable="disableReset" (click)="resetForm()"
                [disabled]="!password1 && !password2">RESET</button>
            </div>

            <div class="col-12 sm:col-6 md:col-4 lg:col-6 p-2">
              <div *ngIf="passwordMismatchError" class="error-message">
                {{ passwordMismatchError }}
              </div>
            </div>

          </div>
        </p-card>

      </div>

    </form>
    <p-toast position="bottom-right"></p-toast>
    <p-confirmPopup></p-confirmPopup>
  </ng-template>
</page>