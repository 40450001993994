import { Component, OnInit } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GetViewProfileJobInformationDto } from "src/app/dto/GetViewProfileJobInformationDto";

@Component({
  selector: 'view-profile-job-information',
  templateUrl: './view-profile-job-information.component.html',
  styleUrls: ['./view-profile-job-information.component.css']
})
export class ViewProfileJobInformationComponent implements OnInit {
  userloggedIn: string;
  ui: GetViewProfileJobInformationDto;
  showLoader = true;

  constructor(private commonService: Commonservice, private localStore: LocalStore) {
  }


  ngOnInit() {
    const self = this;
    self.showLoader = true;
    self.ui = {} as GetViewProfileJobInformationDto;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getViewProfileJobInformation(self.userloggedIn).subscribe( 
        data => {
          self.ui = data;
          self.showLoader = false;
        },
        error => {}
      );
    });
  
  }
}
