import { Component, OnInit } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { GetViewProfileBasicDto } from "src/app/dto/GetViewProfileBasicDto";

@Component({
  selector: 'view-profile-basic',
  templateUrl: './view-profile-basic.component.html',
  styleUrls: ['./view-profile-basic.component.css']
})
export class ViewProfileBasicComponent implements OnInit {
  userloggedIn: string;
  ui: GetViewProfileBasicDto;
  showLoader = true;
  currentDeviceState: string = "";

  constructor(private commonService: Commonservice, private localStore: LocalStore, private signalStateService: SignalStateService) {
  }


  ngOnInit() {
    const self = this;
    self.showLoader = true;
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)(); 
    self.ui = {} as GetViewProfileBasicDto;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getViewProfileBasic(self.userloggedIn).subscribe(
        data => {
          self.ui = data;
          self.showLoader = false;
        },
        error => { }
      );
    });

  }
}
