<div id="myModalDisplayComponents" class="modal">
    <div class="modal-content">
        <div class="row">
            <div class="col-md-12 text-center">
                <p>{{dataBeingDisplayed}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <button (click)=closewindow()  pButton color="primary">Ok</button>
            </div>
        </div>
    </div>
</div>