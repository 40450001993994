<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>
<ng-containter *ngIf="!showLoader">
  <div class="my-horizontal-list">
    <div>
      <div class="table-responsive">
        <table class="table" *ngIf="savedSearches?.length > 0">
          <tr>
            <td>
              <div class="my-horizontal-list saved-searches-container">
              


                <div
                  *ngFor="let item of savedSearches | paginate: { itemsPerPage: 10, currentPage: savedSearchPageNumber, id: 'SavedSearches' }"
                  style="margin-right: 5px">
                  <button pButton class="p-button-text custom-tag-button" (click)="navigateToSearch($event,item)">
                    <span class="button-text">{{ item.searchName }}</span>
                    <i class="pi pi-times close-icon" (click)="removeSearch($event,item)"></i>
                  </button>

                </div>


              </div>
            </td>
          </tr>
          <tr>
            <td>

            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>

  <div style="margin-top: 10px; text-align: center" *ngIf="savedSearches?.length>0">
    <pagination-controls (pageChange)="savedSearchPageNumber = $event" id="SavedSearches">
    </pagination-controls>
  </div>

  <div *ngIf="savedSearches?.length === 0" class="no-value-text-saved">
    No saved searches yet
  </div>
</ng-containter>

<p-confirmPopup></p-confirmPopup>
<p-toast position="bottom-right"></p-toast>