<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Name</div>
  </div>
  <div class="col-4">
    <input
      pInputText
      placeholder="Name"
      [(ngModel)]="ui.Reference_Ker_Name"
      style="width: 100%"
    />
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Address</div>
  </div>
  <div class="col-4">
    <input
      pInputText
      placeholder="Address"
      [(ngModel)]="ui.Reference_Ker_Address"
      style="width: 100%"
    />
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Phone</div>
  </div>
  <div class="col-4">
    <input
      pInputText
      placeholder="Phone"
      [(ngModel)]="ui.Reference_Ker_Phone"
      style="width: 100%"
    />
  </div>
</div>
<div class="flex py-1">
  <div class="col-10"></div>
  <div class="col-2 text-right">
    <button pButton (click)="saveInformation()">SAVE</button>
  </div>
</div>

<p-toast position="bottom-right"></p-toast>