import { Injectable } from '@angular/core';
import { UserInformation } from '../../../db.service';
import { SignalStateService } from '../servicecenter/StateService';

@Injectable()
export class LocalStore {
  obj: string = "";

  constructor(private signalStateService: SignalStateService) {}
  
  getCurrentLoggedInUserSync(): UserInformation {
    let userLoggedIn = JSON.parse(this.signalStateService.getValue('loginResponse')() || '{}') as UserInformation;    
    return userLoggedIn;
  }

  async getCurrentLoggedInUser(): Promise<UserInformation> {
    let userLoggedIn = JSON.parse(this.signalStateService.getValue('loginResponse')() || '{}') as UserInformation;
    return userLoggedIn;
  }
}
