<div ng-if="loading == true">
  <div class="barelist-full-height">
    <div>
      <topbar></topbar>
    </div>
    <div>
      <div class="container top-space">
        <!-- <div>
          <div>currentDevice: {{ currentDevice }}</div>
        </div> -->

        <ng-container [ngSwitch]="currentDevice">
          <ng-container *ngSwitchCase="'mobile-portrait'">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
          </ng-container>


          <ng-container *ngSwitchCase="'mobile-landscape'">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'tablet-portrait'">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'tablet-landscape'">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'foldable-portrait'">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'desktop-portrait'">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
          </ng-container>


          <ng-container *ngSwitchCase="'foldable-landscape'">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
          </ng-container>


          <ng-container *ngSwitchCase="'desktop-landscape'">
            <div class="custom-grid kill-margin">
              <div class="grid-item item-1">
                <menu-search></menu-search>
                <menu-left></menu-left>
              </div>
              <div class="grid-item item-2">
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'laptop-landscape'">
            <div class="custom-grid kill-margin">
              <div class="grid-item item-1">
                <menu-search></menu-search>
                <menu-left></menu-left>
              </div>
              <div class="grid-item item-2">
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <div class="flex flex-wrap kill-margin">
              <div class="col-12 sm:col-6 md:col-4 lg:col-1">
                <menu-search></menu-search>
                <menu-left></menu-left>
              </div>
              <div class="col-12 sm:col-6 md:col-4 lg:col-11">
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
              </div>
            </div>
          </ng-container>


        </ng-container>



      </div>
      <div class="chat-window-zone">
        <app-chat-window></app-chat-window>
      </div>
      <div>
        <my-footer></my-footer>
      </div>
    </div>

    
  </div>