<!-- The Modal -->
<div id="myModalConfirmModelYesOrNo" class="modal">
  <div class="modal-content">
    <div class="row">
      <div class="col-md-12 text-center">
        <p>{{questionToAsk}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-center">
        <button (click)=CallParentMethod(OkayId) pButton color="primary"> Yes</button>
      </div>
      <div class="col-md-6">
        <button (click)=closewindow() pButton>No</button>
      </div>
    </div>
  </div>

</div>