import {
    Component,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { confirmResponseModel } from './model.component.domain';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'responsemodel',
    templateUrl: './model.component.html',
    styleUrls: ['./model.component.css']
})
export class ResponseModelComponent implements OnInit {
    @Output() confirmaction = new EventEmitter<confirmResponseModel>();
    response: string;
    replyToId: string;

    ngOnInit(): void {
        const self = this;
        self.response = '';
    }

    show(idToDelete: number) {
        const self = this;
        const modal = document.getElementById('myModalConfirmWithResponse');
        self.replyToId = idToDelete.toString();
        modal.style.display = 'block';
    }

    hide() {
        const modal = document.getElementById('myModalConfirmWithResponse');
        modal.style.display = 'none';
    }
    CallParentMethod(passedValue: string, response: string) {
        const self = this;
        const crm = {} as confirmResponseModel;
        crm.id = passedValue;
        crm.reponse = response;
        self.confirmaction.next(crm);
        self.hide();
    }

    closewindow() {
        const modal = document.getElementById('myModalConfirmWithResponse');
        modal.style.display = 'none';
    }
}
