import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ContactUsDto } from 'src/app/dto/ContactusDto';

@Component({
  selector: 'app-publiccontactus',
  templateUrl: './publiccontactus.component.html',
  styleUrls: ['./publiccontactus.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class PubliccontactusComponent implements OnInit {
  ctu = {} as ContactUsDto;
  showLiteLoader: boolean = false;
  constructor(private commonService: Commonservice, private messageService: MessageService) { }

  ngOnInit() {
  }

  sendMail(form: any) {
    this.showLiteLoader = true;
    if (form.valid) {
      this.commonService.sendContactUsMail(this.ctu).subscribe(response => {
        this.showLiteLoader = false;
        if (response.InsertWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: response.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: response.Message });
        }
      })
    } else {

    }
  }

}
