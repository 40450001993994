<page>
  <!-- <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="candidateName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.candidateName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="candidateAge">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Age </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.candidateAge}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="candidateDenomination">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Denomination </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.candidateDenomination}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="candidateHeight">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Height </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.candidateHeight}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="candidateOccupation">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Occupation </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.candidateOccupation}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
</mat-table> -->

<!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator> -->

</page>