import { Commonservice } from './../../../common/reusable/servicecenter/commonservice.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SearchResultByIdModel } from "src/app/dto/SearchResultByIdModel";
import * as _ from 'lodash';
import { inboxDateSingle } from '../../mymessages/inbox/inbox.component.domain';


@Component({
  selector: 'privatesearchbyprofile',
  templateUrl: './privatesearchbyprofile.component.html',
  styleUrls: ['./privatesearchbyprofile.component.sass']
})
export class PrivatesearchbyprofileComponent implements OnInit {
  id: string;

  sortedData: inboxDateSingle[];
  private sub: any;
  displayedColumns = ['candidateName', 'candidateAge', 'candidateDenomination', 'candidateHeight', 'candidateOccupation'];  
  dataSource:any;
  initialPage: Object;
  searchResult: Array<SearchResultByIdModel>;

  constructor(private route: ActivatedRoute, private cs: Commonservice) { }

  ngOnInit() {
    const self = this;
    self.sub = self.route.params.subscribe(params => {
      self.id = (params['id']).toString();
      self.getDataBasedonId(self.id);
    });
    self.cs.addCrib(false);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // sortData(sort: Sort) {
  //   this.sortedData = _.orderBy(this.dataSource, [sort.active], [sort.direction]);
  // }

  getDataBasedonId(id: string) {
    const self = this;
    self.cs.searchBasedOnId(id).subscribe(
      data => {
        if (data != undefined) {
          self.searchResult = data;
          // self.dataSource = new MatTableDataSource(self.searchResult);
          // self.dataSource.paginator = self.paginator;
          // self.dataSource.sort = self.sort;
          self.initialPage = { pageSizes: true, pageCount: 4 };
        }
      },
      error => {}
    );
  }

}
