<ng-containter *ngIf="showLoader">
    <container-loader></container-loader>
</ng-containter>
<ng-containter *ngIf="!showLoader">
    <p-card header="">
        <div *ngIf="images" class="grid">
            <div *ngFor="let image of images; let index = index" class="col-2" key="index">
                <p-card header="">
                    <img [src]="image.CandidateImage" class="image-shell" (click)="
                makeitbig(
                    image.ImageName,
                    userloggedIn
                )
              " />
                </p-card>
            </div>
        </div>
    </p-card>


    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '40vw' }" [closable]="true">
        <img [src]="mainImage" class="large-image" />
    </p-dialog>

</ng-containter>