<div class="barelist">
  <div>
    <div class="row">
      <div class="col-md-3 left-sub-title">Id</div>
      <div class="col-md-9">
        {{ mc.UserId }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Gender</div>
      <div class="col-md-9">
        {{ mc.CandidateGender }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Age</div>
      <div class="col-md-9">
        {{ mc.Age }}yrs
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Complexion</div>
      <div class="col-md-9">
        {{ mc.Complexion }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Height</div>
      <div class="col-md-9">{{ mc.Height }} cm</div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Weight</div>
      <div class="col-md-9">{{ mc.Weight }} kg</div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Body Type</div>
      <div class="col-md-9">
        {{ mc.BodyType }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Marital Status</div>
      <div class="col-md-9">
        {{ mc.MaritalStatus }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Physical Status</div>
      <div class="col-md-9">
        {{ mc.Physicalstatus }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Family Status</div>
      <div class="col-md-9">
        {{ mc.FamilyStatus }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Blood Group</div>
      <div class="col-md-9">
        {{ mc.BloodGroup }}
      </div>
    </div>
  </div>
</div>