<div class="barelist">
  <div>    
    <div class="row">
      <div class="col-md-3 left-sub-title">Christian Denomination</div>
      <div class="col-md-9">
        {{ mc.Denomination }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Diocese</div>
      <div class="col-md-9">
        {{ mc.Diocese }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Native Place</div>
      <div class="col-md-9">
        {{ mc.CandidateNative }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Native Town/State /Country</div>
      <div class="col-md-9">
        {{ mc.CityOrDistrict }}
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-3 left-sub-title">Residing Town/State/Country</div>
      <div class="col-md-9">
        {{ mc.CurrentAddress }}
      </div>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-9"></div>
    </div>
  </div>
</div>
