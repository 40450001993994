<div [ngClass]="isExpanded ? 'chat-window-expanded' : 'chat-window'">
  <div class="chat-header">
    <h3>Messaging.. </h3>
    <i [ngClass]="isExpanded ? 'pi pi-angle-down chat-arrow' : 'pi pi-angle-up chat-arrow'" (click)="expandList()"></i>
  </div>
  <div class="search-box" *ngIf="isExpanded">
    <div class="search-box-input">
      <input type="text" [(ngModel)]="searchText" class="search-text" placeholder="Search"
        (ngModelChange)="globalSearch($event)">
    </div>
    <div class="search-box-close">      
      <i class="pi pi-times" tooltipPosition="left"  (click)="clearSearch()" pTooltip="Clear Search"> </i>
    </div>
  </div>
  <div [ngClass]="isExpanded ?  'chat-messages' : 'chat-messages-hidden'">
    <div class="message" *ngFor="let user of users" (click)="openDetailedChat(user)">{{ user.DisplayName }}</div>
  </div>
</div>