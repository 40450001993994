import { Component, OnInit } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';

@Component({
  selector: 'app-publichome',
  templateUrl: './publichome.component.html',
  styleUrls: ['./publichome.component.css']
})
export class PublichomeComponent implements OnInit {
  currentDeviceState: string = "";
  currentClass: string;
  constructor(private commonservice: Commonservice, private signalStateService: SignalStateService) { }

  ngOnInit() {
    const self = this;
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();
    self.commonservice.addCrib(false);
    this.setScreenClass(this.currentDeviceState);
  }

  setScreenClass(screenType: string) {
    switch (screenType) {
      case "desktop-landscape":
        this.currentClass = 'slide-show-zone';
        break;
      case "mobile-portrait":
        this.currentClass = 'slide-show-zone-mobile';
        break;
      case "tablet-portrait":
        this.currentClass = 'slide-show-zone-mobile';
        break;
      default:
        this.currentClass = 'slide-show-zone';
        break;
    }
  }


}
