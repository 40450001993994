import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { environment } from "src/environments/environment";
import { localApiPath } from "../contants/app.constants";
import { finalize, tap } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SignalBasedService {
    connect = environment.apiUrl;
    private apiData = signal<any>(null);
    private isLoading = signal<boolean>(false);

    constructor(private http: HttpClient) { }

    getApiData() {
        return this.apiData.asReadonly();
    }

    getIsLoading() {
        return this.isLoading.asReadonly();
    }

    fetchData(username: string) {
        this.isLoading.set(true);
        return this.http.get<EditPageMegaBucket>(this.connect + '/HomePage/GetEditPageObjects/' + username).pipe(
            finalize(() => this.isLoading.set(false)),
            tap(data => this.apiData.set(data))
        );
    }
}