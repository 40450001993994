<ng-containter *ngIf="showLoader">
  <loader></loader>
</ng-containter>
<div class="hor-row">
  <div class="row" style="width: 100%">
    <div class="r-p-0 col-5">
      <img [src]="tc?.MainImage" 
        class="image-display img-thumbnail" />
    </div>
    <div class="col-7">
      <div class="gallery-grid">
        <figure *ngFor="let item of tc?.ThumbNailImages" class="gallery-frame">
          <img [src]="item.Image" class="image-display-thumbnail img-thumbnail gallery-img"
            (click)="callLargeImage(item.Id)" />
        </figure>
      </div>
      <div class="parent-container">
        <div class="icons-container">
          <div class="icon">
            <img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
              src="../../../../assets/image/newicons/love-message-4669.png" width="30" height="26"
              style="cursor: pointer;" alt="" class="rc-ito-3" (click)="expressInterestPop($event)" />
          </div>
          <div class="icon">
            <img src="../../../../../../assets/image/newicons/bookmark.png" pTooltip="BOOKMARK" tooltipPosition="top"
              width="30" height="26" class="rc-ito-3" style="cursor: pointer;" (click)="bookmarkprofile()">
          </div>
          <div class="icon">          
              <img pTooltip="VIEW PROFILE" (click)="navigateToDetails(tc)" tooltipPosition="top" src="../../../../assets/image/newicons/id-card.png"
                class="rc-ito-3" alt="" />
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-toast position="bottom-right"></p-toast>
<p-confirmPopup></p-confirmPopup>