<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Fathers Name</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Fathers Name" [(ngModel)]="ui.Father_Name" style="width: 100%" />
  </div>
  <div class="col-2">
    <div class="position-label">Fathers House Name</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="House Name" [(ngModel)]="ui.Father_HouseName" style="width: 100%" />
  </div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Fathers Country</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="fatherCountries" [(ngModel)]="fatherCountrySelectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" (onChange)="onFatherCountryChange($event)" [filter]="true"
      filterBy="Name" [showClear]="true" placeholder="Select a Country"></p-dropdown>
  </div>
  <div class="col-2">
    <div class="position-label">Fathers State</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="fatherStates" [(ngModel)]="fatherStateSelectedValue" optionLabel="State" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" (onChange)="onFatherStateChange($event)" [filter]="true"
      filterBy="Name" [showClear]="true" placeholder="Select a state"></p-dropdown>
  </div>

</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Fathers City</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="fatherCities" [(ngModel)]="fatherCitySelectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
      placeholder="Select a city"></p-dropdown>
  </div>
  <div class="col-2">
    <div class="position-label">Fathers Occupation</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="fathersOccupation" [(ngModel)]="fathersOccupationselectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
      placeholder="Select a job"></p-dropdown>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Mothers Name</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Mothers Name" [(ngModel)]="ui.Mother_Name" style="width: 100%" />
  </div>
  <div class="col-2">
    <div class="position-label">Mothers House Name</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Mothers House Name" [(ngModel)]="ui.Mother_HouseName" style="width: 100%" />
  </div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Mothers Country</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="mothersCountries" [(ngModel)]="mothersCountrySelectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" (onChange)="onMotherCountryChange($event)" [filter]="true"
      filterBy="Name" [showClear]="true" placeholder="Select a Country"></p-dropdown>
  </div>
  <div class="col-2">
    <div class="position-label">Mothers State</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="mothersStates" [(ngModel)]="mothersStateSelectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" (onChange)="onMotherStateChange($event)" [filter]="true"
      filterBy="Name" [showClear]="true" placeholder="Select a state"></p-dropdown>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Mothers City</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="mothersCities" [(ngModel)]="mothersCitySelectedValue"  optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
      placeholder="Select a city"></p-dropdown>
  </div>

  <div class="col-2">
    <div class="position-label">Mothers Occupation</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="mothersOccupation" [(ngModel)]="mothersOccupationselectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
      placeholder="Select a job"></p-dropdown>
  </div>
</div>
<div class="flex py-1">
  <div class="col-12">
    <div class="flex py-1">
      <div class="col-2"></div>
      <div class="col-2">
        <label for="Brother_Married" style="display: block">Brother Married</label>
        <p-inputNumber [(ngModel)]="ui.Brother_Married" mode="decimal" [showButtons]="true" inputId="Brother_Married"
          [min]="0" [max]="100">
        </p-inputNumber>
      </div>
      <div class="col-2">
        <label for="Brothers_Unmarried" style="display: block">Brother UnMarried</label>
        <p-inputNumber [(ngModel)]="ui.Brothers_Unmarried" mode="decimal" [showButtons]="true"
          inputId="Brothers_Unmarried" [min]="0" [max]="100">
        </p-inputNumber>
      </div>
      <div class="col-2">
        <label for="Brother_Priest" style="display: block">Brother Priest</label>
        <p-inputNumber [(ngModel)]="ui.Brother_Priest" mode="decimal" [showButtons]="true" inputId="Brother_Priest"
          [min]="0" [max]="100">
        </p-inputNumber>
      </div>
      <div class="col-4"></div>
    </div>
    <div class="flex py-1">
      <div class="col-2"></div>
      <div class="col-2">
        <label for="Sisters_Married" style="display: block">Sisters Married</label>
        <p-inputNumber [(ngModel)]="ui.Sisters_Married" mode="decimal" [showButtons]="true" inputId="Sisters_Married"
          [min]="0" [max]="100">
        </p-inputNumber>
      </div>
      <div class="col-2">
        <label for="Sisters_Unmarried" style="display: block">Sisters Unmarried</label>
        <p-inputNumber [(ngModel)]="ui.Sisters_Unmarried" mode="decimal" [showButtons]="true"
          inputId="Sisters_Unmarried" [min]="0" [max]="100">
        </p-inputNumber>
      </div>
      <div class="col-2">
        <label for="Sisters_Nun" style="display: block">Sisters Nun</label>
        <p-inputNumber [(ngModel)]="ui.Sisters_Nun" mode="decimal" [showButtons]="true" inputId="Sisters_Nun" [min]="0"
          [max]="100">
        </p-inputNumber>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</div>
<br />
<div class="flex py-1">
  <div class="col-10"></div>
  <div class="col-2 text-right">
    <button pButton (click)="saveInformation()">SAVE</button>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>