import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ViewExternalService } from '../../../view/view.component.service';
import { UserImageList } from 'src/app/dto/UserImageCollection';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';

@Component({
  selector: 'candidate-personal-image-collection',
  templateUrl: './candidate-personal-image-collection.component.html',
  styleUrls: ['./candidate-personal-image-collection.component.css']
})
export class CandidatePersonalImageCollectionComponent implements OnInit {
  userWhosInformationIsRequired: string;
  userloggedIn: string;
  showLoader = true;
  showLargeImage = false;
  public images: UserImageList;
  imageToDisplay: HostWindowModel;
  mainImage: string = "";
  userId: string;
  displayCustom: boolean | undefined;
  activeIndex: number = 0;
  responsiveOptions: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  constructor(private commonservice: Commonservice, private viewExtService: ViewExternalService, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;
    this._Activatedroute.params.subscribe(params => {
      self.userWhosInformationIsRequired = params['id'];
    });
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.getUserInfo();
    });
  }

  async getUserInfo() {
    const self = this;
    self.showLoader = true;
    self.commonservice.getUserImageCollection(self.userWhosInformationIsRequired).subscribe(
      data => {
        self.run(data);
      },
      error => { }
    );
  }

  public async run(data: any) {
    const self = this;
    self.images = data;
    self.showLoader = false;
  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  makeitbig(imageThumbnailId: string) {
    const self = this;
    if (imageThumbnailId != null) {
      let largerImageId = imageThumbnailId.replace("Tmb", "");
      self.commonservice.getLargeMainImage(largerImageId).subscribe(data => {
        self.showLargeImage = true;
        self.mainImage = data.MainImage;
      });
    }
    else {
    }
  }

}
