<publicpage>
  <ng-containter *ngIf="showLiteLoader">
    <loader></loader>
  </ng-containter>
  <form #emailForm="ngForm" (ngSubmit)="sendMail(emailForm)">
    <div class="title">
      Contact Us
    </div>
    <div class="form-group">
      <div header="Contact Us" class="child">
        <input type="text" id="Name" name="Name" [(ngModel)]="ctu.Name" required #NameField="ngModel"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
          placeholder="Name">
        <div *ngIf="NameField.touched && NameField.invalid" class="error">
          <div *ngIf="NameField.errors.required">Name is required.</div>
        </div>
      </div>
    </div>
    <div class=" col">
      <div class="form-group">
        <input type="email" id="Email" name="Email" [(ngModel)]="ctu.Email" required #EmailField="ngModel"
          placeholder="Email Address"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
        <div *ngIf="EmailField.touched && EmailField.invalid" class="error">
          <div *ngIf="EmailField.errors.required">Email is required.</div>
          <div *ngIf="EmailField.errors.email">Invalid email format.</div>
        </div>
      </div>
    </div>

    <div class=" col">
      <input type="tel" id="PhoneNumber" name="PhoneNumber" [(ngModel)]="ctu.PhoneNumber" required
        #PhoneNumber="ngModel" placeholder="Phone Number"
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
      <div *ngIf="PhoneNumber.touched && PhoneNumber.invalid" class="error">
        <div *ngIf="PhoneNumber.errors.required">Phone number is required.</div>
      </div>
    </div>

    <div class=" col">
      <textarea id="Message" name="Message" [(ngModel)]="ctu.Message" required #MessageField="ngModel" rows="4"
        cols="45" style="width: 100%;" pInputTextarea placeholder="Message"></textarea>
      <div *ngIf="MessageField.touched && MessageField.invalid" class="error">
        <div *ngIf="MessageField.errors.required">Message is required.</div>
      </div>
    </div>
    <div class=" col">
      <button type="submit" class="myButton" [disabled]="emailForm.invalid">Send</button>
    </div>
  </form>
  <p-toast position="bottom-right"></p-toast>

</publicpage>