import { HttpClient } from '@angular/common/http';
import { Injectable, WritableSignal, effect, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { AddNewChatMessageRequest } from 'src/app/dto/AddNewChatMessageRequest';
import { ChatResponse } from 'src/app/dto/ChatResponse';
import { SaveResponseModel } from 'src/app/dto/SaveResponseModel';
import { ChatUserListResponse } from 'src/app/dto/usersReadyForChat';
import { environment } from 'src/environments/environment';
import { SignalStateService } from './StateService';


@Injectable({
    providedIn: 'root'
})
export class ChatService {
    private readonly STORAGE_KEY = 'selectedUser';
    private readonly API_URL = environment.apiUrl;  
  
    selectedUser: WritableSignal<ChatUserListResponse | null> = signal<ChatUserListResponse>({ UserID: "", DisplayName: "", IsOnline: false });

    constructor(private http: HttpClient) {
        const savedUser =  sessionStorage.getItem(this.STORAGE_KEY);
        if (savedUser !== "undefined") {
            this.selectedUser = signal(savedUser ? JSON.parse(savedUser) : null);
        }

        // Effect to save state changes to sessionStorage
        effect(() => {
            this.saveSelectedUser();
        });
    }

    selectUser(username: ChatUserListResponse) {
        this.selectedUser.set(username);
    }

    private saveSelectedUser() {
        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.selectedUser()));
    }

    // New method to add a chat message
    addNewChat(message: AddNewChatMessageRequest): Observable<SaveResponseModel> {
        return this.http.post<SaveResponseModel>(`${this.API_URL}/api/Chat/SendChat`, message);
    }

    // New method to retrieve chat messages
    getChatMessages(sender: string, recipient: string): Observable<ChatResponse[]> {
        return this.http.get<ChatResponse[]>(`${this.API_URL}/api/Chat/GetChatResponses/${sender}/${recipient}`);
    }
}