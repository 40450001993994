import { ExpressInterestModel } from "src/app/dto/ExpressInterestModel";
import { ExpressInterestSendModel } from "src/app/dto/ExpressInterestSendModel";
import { SendMessageModelCsKind } from "src/app/dto/SendMessageModelCsKind";
import { SendMessageGeneric } from "src/app/dto/SendMessageGeneric";
import { ExpressInterestCarrier } from "src/app/dto/ExpressInterestCarrier";
import { AddBookmarkModel } from "src/app/dto/AddBookmarkModel";
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import *  as _ from 'lodash-es';
import {
  RadioButtonSource,
  QuickSearchParam,
  QuickSearchResult,
  SearchResult
} from './quicks.component.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { DatePipe } from '@angular/common';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { ActionResponse } from "src/app/dto/ActionResponse";
import { concatMap, forkJoin, map, tap } from "rxjs";
import { trigger, style, transition, animate } from "@angular/animations";
import { ScreenSizeService } from "src/app/common/reusable/servicecenter/ScreenSizeService";
import { PrefetchBackGroundService } from "src/app/common/reusable/servicecenter/PrefetchBackGroundService";
import { Dropdown } from "src/app/dto/Dropdown";
import { dropdown } from "src/app/dto/dropdown.1";
import { Router } from "@angular/router";
import { SignalStateService } from "src/app/common/reusable/servicecenter/StateService";
import { APP_CONSTANTS } from "src/app/common/reusable/contants/app.constants";
import { GlobalLoaderService } from "src/app/common/reusable/servicecenter/global-loader-service";

@Component({
  templateUrl: './quicks.component.html',
  styleUrls: ['./quicks.component.css'],
  providers: [MessageService, ConfirmationService, ScreenSizeService],
  animations: [trigger('slideDownUp', [
    transition(':enter', [style({ height: 0 }), animate(500)]),
    transition(':leave', [animate(500, style({ height: 0 }))]),
  ])]
})
export class QuickSearchComponent implements OnInit, OnDestroy {
  public textWrapSettings;
  confirmationTitle: string = "Confirmation";
  heightArrayFrom: Array<Dropdown>;
  heightArrayTo: Array<Dropdown>;
  ageArrayFrom: Array<Dropdown>;
  ageArrayTo: Array<Dropdown>;
  ageFromSelected: Dropdown | null = null;;
  ageToSelected: Dropdown;
  heightFromSelected: Dropdown;
  heightToSelected: Dropdown;
  showProfileWithImage: boolean;
  placeHolderStates: string = "";
  placeHolderCountry: string = "Nationality";
  placeHolderCity: string = "";
  noresult = false;

  mtd: ExpressInterestModel;
  nativePlace: Array<Dropdown>;
  community: Array<Dropdown>;
  lookingFor: Array<Dropdown>;
  searchResult: Array<SearchResult>;
  groomOrBride: RadioButtonSource;
  communitySource: RadioButtonSource;
  searchParameter: QuickSearchParam;
  showSearchBox: boolean;
  saveSearch: boolean = false;
  searchName = "";
  first = 0;
  rows = 10;
  totalRecords = 0;
  genderSelected: Dropdown;
  userloggedIn: string;
  expressInterestModel: ExpressInterestSendModel;
  showLargeImage = false;
  imageToDisplay: HostWindowModel;
  userId: string;
  sendMessageText: string;
  showLoader = false;
  states: Dropdown[] = [];
  cities: Dropdown[] = [];
  nationality: Dropdown[] = [];
  nationalitySelectedValue: Dropdown;
  stateSelectedValue: Dropdown;
  cityPlaceSelectedValue: Dropdown;
  showCity = false;
  showState = false;
  showImageLoader = false;
  animationState = 'in';



  page: number = 1;
  size: number = 10;
  paginationCount: number = 0;
  loadFromPresavedSearch: boolean = false;
  showPreSavedLoad: boolean = false;


  currentDeviceState: string = "";


  // Track pages that have already been fetched or prefetched
  private prefetchedPages = new Set<number>();
  private prefetchedData: { [page: number]: any[] } = {};


  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;
  smg: SendMessageGeneric;

  constructor(private commonService: Commonservice, private datePipe: DatePipe, private router: Router, private localStore: LocalStore, private messageService: MessageService, private confirmationService: ConfirmationService, private signalStateService: SignalStateService, private loaderService: GlobalLoaderService) {



  }

  ngOnInit() {
    const self = this;
    self.loadIniital();

  }

  loadIniital() {
    const self = this;
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();
    this.loaderService.loader$.subscribe(loader => { this.showLoader = loader; });
    self.showSearchBox = true;
    self.heightArrayFrom = [];
    self.heightArrayTo = [];
    self.nativePlace = [];
    self.community = [];
    self.lookingFor = [];
    self.ageArrayFrom = [];
    self.nationality = [];
    self.ageArrayTo = [];
    self.ageArrayTo = [];
    self.heightArrayTo = [];
    self.groomOrBride = {} as RadioButtonSource;
    self.groomOrBride.source = [] as Array<Dropdown>;
    self.communitySource = {} as RadioButtonSource;
    self.communitySource.source = [] as Array<Dropdown>;
    self.searchParameter = {} as QuickSearchParam;
    self.showProfileWithImage = false;
    self.searchResult = [];
    self.imageToDisplay = {} as HostWindowModel;
    self.placeHolderStates = "State";
    self.loadGroomAndBrideSource();
    self.loadCommunitySource();
    self.localStore.getCurrentLoggedInUser().then((result) => {
      let quickSearch = sessionStorage.getItem("QuickSearch");
      self.userloggedIn = result.UserName;
      self.textWrapSettings = { wrapMode: 'both' };
      const fromAge = self.commonService.getAge();
      const toAge = self.commonService.getAge();
      const fromHeight = this.commonService.getHeightToLocal();
      const toHeight = this.commonService.getHeightToLocal();
      const nationality = self.commonService.getCountry();
      this.loaderService.showLoader();
      forkJoin([
        fromAge,
        toAge,
        fromHeight,
        toHeight,
        nationality
      ])
        .pipe(
          tap(() => {
            //self.showLoader = false; // Turn off the loader after all calls are complete
          })
        ).subscribe(
          (s) => {

            let quickSearch: QuickSearchParam = JSON.parse(sessionStorage.getItem("QuickSearch"));
            // age from            
            _.forEach(_.nth(s, 0), function (Value: dropdown) {
              const ageFrom = {} as Dropdown;
              ageFrom.Value = Value.value;
              ageFrom.Name = Value.name.toString();
              self.ageArrayFrom.push(ageFrom);
            });

            // if (quickSearch !== null) {
            //   self.ageFromSelected = self.ageArrayFrom[self.ageArrayFrom.findIndex(x => x.Value === quickSearch.StarteAge)];
            // }

            if (quickSearch !== null) {
              self.ageFromSelected = self.ageArrayFrom[self.ageArrayFrom.findIndex(x => x.Value.toString() === quickSearch.StarteAge.toString())];
            }
            // age from

            // age to
            _.forEach(_.nth(s, 1), function (Value: dropdown) {
              const ageTo = {} as Dropdown;
              ageTo.Value = Value.value;
              ageTo.Name = Value.name.toString();
              self.ageArrayTo.push(ageTo);
            });

            if (quickSearch !== null) {
              self.ageToSelected = self.ageArrayTo[self.ageArrayTo.findIndex(x => x.Value === quickSearch.EndAge)];
            }
            // age to

            // height from

            _.forEach(_.nth(s, 2), function (Value: dropdown) {
              const fromHeight = {} as Dropdown;
              fromHeight.Value = Value.value;
              fromHeight.Name = Value.name.toString();
              self.heightArrayFrom.push(fromHeight);
            });
            if (quickSearch !== null && quickSearch.StartHeight !== undefined) {
              self.heightFromSelected = self.heightArrayFrom[self.heightArrayFrom.findIndex(x => x.Value.toString() === quickSearch.StartHeight.toString())];
            }
            // height from 

            // height to            
            _.forEach(_.nth(s, 3), function (Value: dropdown) {
              const toHeight = {} as Dropdown;
              toHeight.Value = Value.value;
              toHeight.Name = Value.name.toString();
              self.heightArrayTo.push(toHeight);
            });
            if (quickSearch !== null && quickSearch.EndHeight !== undefined) {
              self.heightToSelected = self.heightArrayTo[self.heightArrayTo.findIndex(x => x.Value.toString() === quickSearch.EndHeight.toString())];
            }
            // height to

            // nationality
            _.forEach(_.nth(s, 4), function (Value: Dropdown) {
              const toHeight = {} as Dropdown;
              toHeight.Value = Value.Value;
              toHeight.Name = Value.Name.toString();
              self.nationality.push(toHeight);
            });

            self.nationalitySelectedValue = self.nationality.find(x => x.Value.toString() === '0');

            this.loaderService.hideLoader();
            // nationality
          },
          error => {
            console.error('API error:', error);
          }
        );;

      if (quickSearch !== null) {
        this.loaderService.showLoader();
        self.searchParameter = JSON.parse(quickSearch);
        self.showProfileWithImage = self.searchParameter.ShowProfileWithImage === "2" ? true : false;
        self.saveSearch = true;
        self.searchName = self.searchParameter.SearchName;
        self.searchFromParams(JSON.parse(quickSearch))
      }
    });

    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
  }

  // this is for load to age
  loadFromAge() {
    const self = this;
    self.commonService.getAge().subscribe(
      data => {
        self.loadFromAgeAsync(data);
      },
      error => { }
    );

  }
  async loadFromAgeAsync(data) {
    const self = this;
    self.ageArrayFrom = [];
    _.forEach(data, function (Value: dropdown) {
      const ageFrom = {} as Dropdown;
      ageFrom.Value = Value.value;
      ageFrom.Name = Value.name.toString();
      self.ageArrayFrom.push(ageFrom);
    });

    let quickSearch: QuickSearchParam = JSON.parse(sessionStorage.getItem("QuickSearch"));
    if (quickSearch !== null) {
      self.ageFromSelected = self.ageArrayFrom[self.ageArrayFrom.findIndex(x => x.Value === quickSearch.StarteAge)];
    }
  }

  loadToAge() {
    const self = this;
    this.commonService.getAge().subscribe(
      data => {
        self.loadToAgeAsync(data);
      },
      error => { }
    );

  }

  async loadToAgeAsync(data) {
    const self = this;
    self.ageArrayTo = [];
    _.forEach(data, function (Value) {
      const ageTo = {} as Dropdown;
      ageTo.Value = Value.Value;
      ageTo.Name = Value.Name.toString();
      self.ageArrayTo.push(ageTo);
    });
    let quickSearch: QuickSearchParam = JSON.parse(sessionStorage.getItem("QuickSearch"));
    if (quickSearch !== null) {
      self.ageToSelected = self.ageArrayTo[self.ageArrayTo.findIndex(x => x.Value === quickSearch.EndAge)];
    }
  }

  // this is for load to height
  loadToHeight() {
    const self = this;
    this.commonService.getHeightToLocal().subscribe(
      data => {
        self.loadToHeightAsync(data);
      },
      error => { }
    );
  }
  async loadToHeightAsync(heights) {
    const self = this;
    self.heightArrayTo = [];
    _.forEach(heights, function (Value) {
      const toHeight = {} as Dropdown;
      toHeight.Value = Value.Value;
      toHeight.Name = Value.Name.toString();
      self.heightArrayTo.push(toHeight);
    });
    let quickSearch: QuickSearchParam = JSON.parse(sessionStorage.getItem("QuickSearch"));
    if (quickSearch !== null && quickSearch.EndHeight !== undefined) {
      self.heightToSelected = self.heightArrayTo[self.heightArrayTo.findIndex(x => x.Value.toString() === quickSearch.EndHeight.toString())];
    }
  }


  // this is for load from height
  loadFromHeight() {
    const self = this;
    this.commonService.getHeightFromLocal().subscribe(
      data => {
        self.loadFromHeightAsync(data);
      },
      error => { }
    );
  }

  async loadFromHeightAsync(heights) {
    const self = this;
    self.heightArrayFrom = [];
    _.forEach(heights, function (Value) {
      const fromHeight = {} as Dropdown;
      fromHeight.Value = Value.Value;
      fromHeight.Name = Value.Name.toString();
      self.heightArrayFrom.push(fromHeight);
    });
    let quickSearch: QuickSearchParam = JSON.parse(sessionStorage.getItem("QuickSearch"));
    if (quickSearch !== null && quickSearch.StartHeight !== undefined) {
      self.heightFromSelected = self.heightArrayFrom[self.heightArrayFrom.findIndex(x => x.Value.toString() === quickSearch.StartHeight.toString())];
    }
  }
  // this is for load from height


  // this is for laoding native place
  loadNationality() {
    const self = this;
    const ccn = self.commonService;
    self.commonService.getCountryTypedropdown().subscribe(
      (data: Array<Dropdown>) => {
        let countrySelected = data[0].Value;

        ccn.getStatesBasedOnCountryTypedropdown(countrySelected.toString()).subscribe((x: Array<Dropdown>) => {
          let stateSelected = x[0].Value;
          ccn.getCitiesBasedOnStateTypedropdown(stateSelected.toString(), countrySelected.toString()).subscribe(y => {
            self.nationality = data;
            self.states.push(...x);
            self.showState = self.states.length > 0 ? true : false;
            self.cities.push(...y);
            self.showCity = self.cities.length > 0 ? true : false;
            self.placeHolderStates = "Select State";
            self.placeHolderCity = "Select City";
          });
        });
      },
      error => { }
    );
  }
  onNationalityChange(event) {
    const self = this;
    const ccn = self.commonService;

    this.showLoader = true;
    ccn.getStatesBasedOnCountryTypedropdown(self.nationalitySelectedValue.Value.toString()).pipe(
      concatMap(x => {
        if (x?.length > 0) {
          self.showState = true;
          self.states = [];
          _.forEach(x, function (Value: Dropdown) {
            const dd = {} as Dropdown;
            dd.Value = Value.Value;
            dd.Name = Value.Name.toString();
            self.states.push(dd);
          });
          self.stateSelectedValue = self.states.find(x => x["Value"].toString() === '0');
          self.placeHolderStates = "please select a state";
        }
        else {
          self.showState = false;
        }
        return ccn.getCitiesBasedOnStateTypedropdown(self.stateSelectedValue["Value"].toString(), self.nationalitySelectedValue["Value"].toString()).pipe(
          map(processedResult2 => {
            self.showState = self.states.length > 0 ? true : false;
            self.cities = [];
            _.forEach(processedResult2, function (Value: Dropdown) {
              const dd = {} as Dropdown;
              dd.Value = Value.Value;
              dd.Name = Value.Name.toString();
              self.cities.push(dd);
            });
            self.showCity = self.cities.length > 0 ? true : false;
            self.cityPlaceSelectedValue = self.cities.find(x => x["Value"].toString() === '0');
            self.placeHolderStates = "State";
            return self.cities;
          })
        )
      })
    )
      .pipe(
        tap(() => {
          this.showLoader = false; // Turn off the loader after all calls are complete
        })
      )
      .subscribe(
        finalResult => {

        },
        error => {
          console.error('API error:', error);
        }
      );
  }

  onStateChange(event) {
    const self = this;
    const ccn = self.commonService;
    // self.stateSelectedValue = event.Value;
    if (self.stateSelectedValue.Value != 0) {
      ccn.getCitiesBasedOnStateTypedropdown(self.stateSelectedValue.Value.toString(), self.nationalitySelectedValue.Value.toString()).subscribe(x => {
        self.cities = [];
        if (x.length > 0) {
          _.forEach(x, function (Value: Dropdown) {
            const dd = {} as Dropdown;
            dd.Value = Value.Value;
            dd.Name = Value.Name.toString();
            self.cities.push(dd);
          });
          self.cityPlaceSelectedValue = self.cities.find(x => x["Value"].toString() === '0');
          self.showCity = true;
        }
        else {
          self.showCity = false;
        }
      });
    }
  }

  appendSelectDropdown2(incoming: Array<Dropdown>): Array<Dropdown> {
    let returnObject: Array<Dropdown> = [];
    let select: Dropdown = {
      Name: "Select",
      Value: 0
    }
    incoming.unshift(select);
    returnObject.push(...incoming);
    return returnObject;
  }

  // this is for search query

  triggerSearch(reset: boolean = false) {
    this.search(reset).then(() => {
      this.prefetchPages();
    });
  }

  search(reset: boolean): Promise<void> {
    const self = this;
    return new Promise((resolve) => {
      {
        if (reset) {
          this.page = 1;
          this.prefetchedPages.clear();
          this.prefetchedData = {};
          self.searchParameter = {} as QuickSearchParam;
        }
        this.loaderService.showLoader();
        self.searchParameter = self.fillSearchParam();
        self.commonService.quickSearch(self.searchParameter).subscribe(
          (data: QuickSearchResult) => {
            if (data != null) {
              this.loaderService.hideLoader();
              const updatedData = data.SearchResult.map(singleDrop => ({
                ...singleDrop,
                PathToProfile: "./../viewotherprofile"
              }));
              this.searchResult = updatedData;
              self.totalRecords = data.TotalRecords;

              // Mark the current page as fetched
              this.prefetchedPages.add(this.page);
              this.prefetchedData[this.page] = updatedData;


              self.noresult = self.searchResult.length > 0 ? false : true;

              resolve();
            }
          },
          error => {
            console.error('Error fetching data:', error);
            resolve();
          }
        );
      }
    })
  };

  resetSearch() {
    const self = this;
    self.loadIniital();
    self.nationalitySelectedValue = {} as Dropdown;
    self.stateSelectedValue = {} as Dropdown;
    self.cityPlaceSelectedValue = {} as Dropdown;
    sessionStorage.removeItem('QuickSearch');
  }

  loadGroomAndBrideSource() {
    const self = this;
    const kvpMale = {} as Dropdown;
    kvpMale.Name = 'Groom';
    kvpMale.Value = 1;
    const kvpFemale = {} as Dropdown;
    kvpFemale.Name = 'Bride';
    kvpFemale.Value = 2;
    self.groomOrBride.source.push(kvpMale);
    self.groomOrBride.source.push(kvpFemale);
    self.genderSelected = self.groomOrBride.source[0];
    let quickSearch: QuickSearchParam = JSON.parse(sessionStorage.getItem("QuickSearch"));
    if (quickSearch !== null) {
      self.genderSelected = self.groomOrBride.source[self.groomOrBride.source.findIndex(x => x.Value === quickSearch.Gender)];
    }
  }

  loadCommunitySource() {
    const self = this;
    const kvpBoth = {} as Dropdown;
    kvpBoth.Name = 'Both';
    kvpBoth.Value = 1;
    const kvpCatholic = {} as Dropdown;
    kvpCatholic.Name = 'Catholic';
    kvpCatholic.Value = 2;
    const kvpNonCatholic = {} as Dropdown;
    kvpNonCatholic.Name = 'Non-Catholic';
    kvpNonCatholic.Value = 3;
    self.communitySource.source.push(kvpBoth);
    self.communitySource.source.push(kvpCatholic);
    self.communitySource.source.push(kvpNonCatholic);
    let quickSearch: QuickSearchParam = JSON.parse(sessionStorage.getItem("QuickSearch"));
    if (quickSearch !== null) {
      self.communitySource.selectedValue = self.communitySource.source[self.communitySource.source.findIndex(x => x.Value.toString() === quickSearch.Denomination)];
    }
  }

  hideSearchBox() {
    const self = this;
    self.showSearchBox = !self.showSearchBox;
  }

  sendMessage(row) {
    const self = this;
    let smi = new SendMessageModelCsKind();
    smi.ToUser = row.candidateUsername;
    smi.FromUser = self.userloggedIn;
    smi.Message = "SEND MESSAGE TO " + row.candidateUsername.toUpperCase();
    self.commonService.sendMessage(smi).subscribe(data => {
      if (data.InsertWorked) {
        this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Service Message', detail: data.Message });
      }
    });
  }

  expressInterestPop(event: Event, row: SearchResult) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = row.CandidateUsername;
    eic.userToName = row.CandidateName.toUpperCase();
    self.mtd = self.commonService.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  sendExpressInterest(event: ExpressInterestModel) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonService.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  bookmarkprofile(row) {
    const self = this;
    let bookmark: AddBookmarkModel = {
      UserWhoIsBookmarking: self.userloggedIn,
      UserWhoIsGettingBookmarked: row.CandidateUsername
    }
    let error: any;
    self.commonService.AddBookMark(bookmark).subscribe(
      data => {
        if (data != null) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        error = data?.Message;
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Service Message', detail: error });
      }
    );
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    this.showLargeImage = true;
    if (imageThumbnailId != null) {
      // this.loaderService.showLoader();
      self.commonService.getLargeImage(imageThumbnailId).subscribe(
        data => {
          // this.loaderService.hideLoader();
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => { }
      );
    }
    else {
    }
  }

  showSendDialogue(mc: SearchResult) {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = mc.CandidateUsername;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonService.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }

  eventPostSubmit(event: ActionResponse) {
    const self = this;
    self.smg.ShowSendMessage = false;
    if (event.ActionState == true) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }

  saveSearchName() {
    const self = this;
    self.searchParameter = {} as QuickSearchParam;
    if (self.ageFromSelected?.Value !== 0) {
      self.searchParameter.StarteAge = self.ageFromSelected?.Value;
    }

    if (self.ageToSelected?.Value !== 0) {
      self.searchParameter.EndAge = self.ageToSelected?.Value;
    }

    if (self.heightFromSelected?.Value !== 0) {
      self.searchParameter.StartHeight = self.heightFromSelected?.Value;
    }

    if (self.heightToSelected?.Value != null) {
      self.searchParameter.EndHeight = self.heightToSelected?.Value;
    }

    if (self.communitySource.selectedValue?.Value != 0) {
      self.searchParameter.Denomination = self.communitySource.selectedValue?.Value?.toString();
    }

    self.searchParameter.Gender = self.genderSelected?.Value;
    self.searchParameter.Country = self.nationalitySelectedValue?.Value.toString() === "0" ? null : self.nationalitySelectedValue?.Value.toString();
    self.searchParameter.State = self.stateSelectedValue?.Value.toString() === "0" ? null : self.stateSelectedValue?.Value.toString();
    self.searchParameter.City = self.cityPlaceSelectedValue?.Value.toString() === "0" ? null : self.cityPlaceSelectedValue?.Value.toString();
    self.searchParameter.ShowProfileWithImage = self.showProfileWithImage ? "2" : "0";
    self.searchParameter.UserLoggedIn = self.userloggedIn;

    if (self.saveSearch) {
      self.searchParameter.SaveSearch = true;

      if (self.searchName.trim().length > 0) {
        self.searchParameter.SearchName = self.searchName;
      }

      self.searchParameter.SearchDate = new Date();
    }
    else {
      self.searchParameter.SaveSearch = false;
    }

    self.searchParameter.AdvancedOrQuick = "1";

    self.searchParameter.PaginationParams = {
      Page: this.page,
      Size: this.size
    }

    self.commonService.SaveSearch(self.searchParameter).subscribe(
      data => {
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        setTimeout(() => {
          self.saveSearch = false;
        }, 900);
      },
      error => { }
    );
  }


  searchFromParams(searchParameter: QuickSearchParam) {
    const self = this;
    searchParameter.SaveSearch = false;
    this.loaderService.showLoader();
    self.commonService.quickSearch(searchParameter).subscribe(
      (data: QuickSearchResult) => {
        if (data != null) {
          const updatedDate = [] as Array<SearchResult>;
          data.SearchResult.forEach(singleDrop => {
            let quickSearchResult = {} as SearchResult;
            quickSearchResult = singleDrop;
            quickSearchResult.PathToProfile = "./../viewotherprofile";
            updatedDate.push(quickSearchResult);
          });
          self.searchResult = updatedDate;
          this.loaderService.hideLoader();
          sessionStorage.removeItem("QuickSearch");
        }
      },
      error => { }
    );
  }

  navigateToDetails(tc: SearchResult) {
    let link = tc.PathToProfile + "/" + tc.CandidateUsername;
    this.router.navigate([]).then(result => { window.open(link, '_blank'); });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem("QuickSearch");
  }

  onPageChange(event: any) {
    const newPage = event.first / event.rows + 1;// Calculate the current page number
    this.size = event.rows;
    this.first = event.first;
    this.rows = event.rows;

    if (this.prefetchedPages.has(newPage)) {
      console.log(`Page ${this.page} is already prefetched. Skipping search.`);
      this.page = newPage;
      this.searchResult = this.prefetchedData[newPage];
      this.prefetchPages();
    } else {
      // Fetch data based on the new page number
      this.page = newPage;
      if (this.loadFromPresavedSearch) {
        this.searchParameter.PaginationParams = {
          Page: this.page,
          Size: this.size
        }
        this.showLoader = true;
        this.searchFromParams(this.searchParameter);
      }
      else {
        this.search(false).then(() => {
          this.prefetchPages();
        });
      }
    }

    // Always prefetch pages after changing the page
    this.prefetchPages();
  }

  onPageSizeChange(newSize: number): void {
    this.size = newSize;
    this.page = 1; // Reset to first page when changing page size
    this.search(false).then(() => {
      this.prefetchPages();
    });
  }

  // Function to prefetch multiple pages
  prefetchPages(): void {
    const pagesToPrefetch = this.size; // Use the current page size as the number of pages to prefetch
    const startPage = Math.max(1, this.page - Math.floor(pagesToPrefetch / 2));

    const prefetchPromises = [];

    for (let i = 0; i < pagesToPrefetch; i++) {
      const pageNum = startPage + i;
      if (!this.prefetchedPages.has(pageNum)) {
        prefetchPromises.push(this.prefetchPage(pageNum));
      }
    }

    Promise.all(prefetchPromises)
      .then(() => console.log('All prefetch requests completed'))
      .catch(error => console.error('Error in prefetching:', error));
  }

  // Function to prefetch a specific page
  prefetchPage(pageNum: number): void {
    const self = this;
    self.searchParameter = self.fillSearchParam();
    this.commonService.quickSearch({
      ...this.searchParameter,
      PaginationParams: { Page: pageNum, Size: this.size }
    }).subscribe(
      (data: QuickSearchResult) => {
        if (data != null) {
          const updatedData = data.SearchResult.map(singleDrop => ({
            ...singleDrop,
            PathToProfile: "./../viewotherprofile"
          }));
          this.prefetchedData[pageNum] = updatedData;
          this.prefetchedPages.add(pageNum);
        }
      },
      error => {

      }
    );
  }

  fillSearchParam(): QuickSearchParam {
    const self = this;

    self.searchParameter = {} as QuickSearchParam;

    if (self.ageFromSelected?.Value !== 0) {
      self.searchParameter.StarteAge = self.ageFromSelected?.Value;
    }

    if (self.ageToSelected?.Value !== 0) {
      self.searchParameter.EndAge = self.ageToSelected?.Value;
    }

    if (self.heightFromSelected?.Value !== 0) {
      self.searchParameter.StartHeight = self.heightFromSelected?.Value;
    }

    if (self.heightToSelected?.Value != null) {
      self.searchParameter.EndHeight = self.heightToSelected?.Value;
    }

    if (self.communitySource.selectedValue?.Value != 0) {
      self.searchParameter.Denomination = self.communitySource.selectedValue?.Value?.toString();
    }

    self.searchParameter.Gender = self.genderSelected?.Value;
    self.searchParameter.Country = self.nationalitySelectedValue?.Value.toString() === "0" ? null : self.nationalitySelectedValue?.Value.toString();
    self.searchParameter.State = self.stateSelectedValue?.Value.toString() === "0" ? null : self.stateSelectedValue?.Value.toString();
    self.searchParameter.City = self.cityPlaceSelectedValue?.Value.toString() === "0" ? null : self.cityPlaceSelectedValue?.Value.toString();
    self.searchParameter.ShowProfileWithImage = self.showProfileWithImage ? "2" : "0";
    self.searchParameter.UserLoggedIn = self.userloggedIn;

    if (self.saveSearch) {
      self.searchParameter.SaveSearch = true;

      if (self.searchName.trim().length > 0) {
        self.searchParameter.SearchName = self.searchName;
      }

      self.searchParameter.SearchDate = new Date();
    }
    else {
      self.searchParameter.SaveSearch = false;
    }

    self.searchParameter.AdvancedOrQuick = "1";
    this.animationState = this.animationState === 'out' ? 'in' : 'out';

    self.searchParameter.PaginationParams = {
      Page: this.page,
      Size: this.size
    }

    return self.searchParameter;
  }
}
