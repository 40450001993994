
export class RegisterNewMemberModel {
    CandidateEmail: string;
    CandidatePhoneNumber: string;
    CandidatePassword: string;
    CandidateFirstName: string;
    CandidateMiddleName: string;
    CandidateLastName: string;
    CandidateMaritalStatus: number;
    CandidateReligionDenomination: number;
    CandidateDateOfBirth: Date;
    CandidateCountry: number;
    CandidateState: number;
    CandidateCity: number;
    CandidateHeight: string;
    CandidateGender: number;
    CandidateOccupation: number;
    CouponCode: string;
}
