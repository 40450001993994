import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CommonFunc {
    searchText(array, text, columnsToSearch) {
        text = text.toLowerCase();
        let retulstantArray = array.filter(function (o) {
            let resultfromColumnSearch = columnsToSearch.some(function (k) {
                let resultArray: boolean = false;
                if (o[k] != undefined) {
                    resultArray = o[k].toString().toLowerCase().indexOf(text.toString().toLowerCase()) !== -1;
                }
                return resultArray;
            });
            return resultfromColumnSearch;
        });
        return retulstantArray;
    }

    searchTextFromStringArray(strArray, text) {
        text = text.toLowerCase();
        let retulstantArray = strArray.filter(x => x.toLowerCase().includes(text));
        return retulstantArray;
    }

    mergeArraysByKey<T, K extends keyof T>(
        existingArray: T[],
        newArray: T[],
        key: K
      ): T[] {
        const newArrayKeys = new Set(newArray.map(item => item[key]));
        
        // Filter out items from existingArray that are not in newArray
        const filteredExistingArray = existingArray.filter(item => newArrayKeys.has(item[key]));
        
        const mergedMap = new Map<T[K], T>();
        
        // Add filtered existing items to the map
        filteredExistingArray.forEach(item => mergedMap.set(item[key], item));
        
        // Update or add new items
        newArray.forEach(item => mergedMap.set(item[key], item));
        
        // Convert map back to array
        return Array.from(mergedMap.values());
      }
}
