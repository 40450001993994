import { Routes } from '@angular/router';
import { GaurdService } from '../../../common/reusable/servicecenter/gaurdService.component';
import { SearchByIdComponent } from './search-by-id.component';



// Route Configuration
export const SearchByIdRoute: Routes = [
  { path: 'searchbyid', canActivate: [GaurdService], component: SearchByIdComponent }
];
