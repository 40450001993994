<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Job </div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.Jobs" [(ngModel)]="jobSelectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
      placeholder="Select a job"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Job Type</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.JobCategory" [(ngModel)]="jobTypeSelectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
      placeholder="Select a job type"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Visa Status</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.VisaStatus" [(ngModel)]="visaStatusSelectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
      placeholder="Select a visa status"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Next Leave</div>
  </div>
  <div class="col-4">
    <p-calendar [(ngModel)]="nextLeave" [showIcon]="true"  placeholder="Next leave"
      [style]="{ width: '100%', overflow: 'visible' }"></p-calendar>      
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Name Of Firm</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Name Of Firm" [(ngModel)]="ui.Firm" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Annual Salary</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Annual Salary" [(ngModel)]="ui.AnnualIncome" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>


<div class="flex py-1">
  <div class="col-10"></div>
  <div class="col-2 text-right">
    <button pButton (click)="saveInformation()">SAVE</button>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>