import { Component, NgZone, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';
import { PrefetchBackGroundService } from 'src/app/common/reusable/servicecenter/PrefetchBackGroundService';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { MemberShipInformationModel } from "src/app/dto/MemberShipInformationModel";

@Component({
  selector: 'user-bare-minimal-profile',
  templateUrl: './user-bare-minimal-profile.component.html',
  styleUrls: ['./user-bare-minimal-profile.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserBareMinimalProfileComponent implements OnInit {
  userInformation: MemberShipInformationModel;
  userloggedIn: string;
  showLargeImage = false;
  userId: string;
  imageToDisplay: HostWindowModel;
  showLoader = false;
  isEmpty = (obj: object): boolean => {
    return Object.entries(obj)?.length === 0;
  };

  constructor(private commonService: Commonservice, private localStore: LocalStore, private pbgs: PrefetchBackGroundService, private signalStateService: SignalStateService) {

  }


  ngOnInit() {
    const self = this;

    self.commonService.get404Image().subscribe(data => { 
      
      self.signalStateService.setValue(data.Name, data.Image); 
    
    });

    // self.commonService.get404Image().subscribe({
    //   next: (blob) => {

    //   },
    //   error: (err) => console.error('Error loading image:', err)
    // });

    // this.loaderService.loader$.subscribe(loader => { this.showLoader = loader; });
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.userInformation = {} as MemberShipInformationModel;
    self.imageToDisplay = {} as HostWindowModel;
    const fromCache = self.signalStateService.getValue("BasicProfileDataSet")();
    if (fromCache) {
      setTimeout(() => {
        self.userInformation = JSON.parse(fromCache);
        // this.loaderService.hideLoader();
      }, 120);
    }
    else {
      // this.loaderService.showLoader();
      self.commonService.getHomeUserProfile(self.userloggedIn).subscribe(
        data => {
          self.userInformation = data;
          self.pbgs.updateBasicProfileInStore(data);
          // this.loaderService.hideLoader();
        },
        error => {

        }
      );
    }
  }

  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (itemId != null) {
      // this.loaderService.showLoader();
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          // this.loaderService.hideLoader();
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {

        }
      );
    }
    else {

    }
  }

}
