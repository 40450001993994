import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { DurationSelected } from 'src/app/dto/DurationSelected';
import { MemberShipDetails } from 'src/app/dto/MemberShipDetails';
import { SaveResponseModel } from 'src/app/dto/SaveResponseModel';
import { UpgradeToPremiumDto } from 'src/app/dto/UpgradeToPremiumDto';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrl: './membership.component.scss',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('500ms ease-in', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ transform: 'translateY(-100%)', opacity: 0 }))
      ])
    ])
  ],
  providers: [MessageService]
})
export class MembershipComponent implements OnInit {
  userloggedIn: string;
  showLoader = true;
  memberShipDetails: MemberShipDetails;
  showExtend = false;
  showPaymentGateWay = false;
  durationSelected: DurationSelected;
  srm: SaveResponseModel;
  constructor(private commonservice: Commonservice, private localStore: LocalStore, private messageService: MessageService) {
  }


  ngOnInit(): void {
    const self = this;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.durationSelected = {
      Amount: 0,
      DurationId: 1,
      RedID: self.userloggedIn,
      Duration: ''
    };
    self.srm = {
      InsertWorked: false,
      Message: ""
    }
    self.commonservice.getMembershipDetails(self.userloggedIn).subscribe(
      data => {
        self.memberShipDetails = data;
        self.showLoader = false;
      },
      error => { }
    );
  }

  extendLifeLine() {
    this.showExtend = !this.showExtend;
  }

  addPaymentGateway(duration: number) {
    const self = this;
    this.showPaymentGateWay = true;
    switch (duration) {
      case 1:
        self.durationSelected = {
          Amount: 200,
          DurationId: 5,
          RedID: self.userloggedIn,
          Duration: '1 month'
        };
        break;
      case 3:
        self.durationSelected = {
          Amount: 600,
          DurationId: 2,
          RedID: self.userloggedIn,
          Duration: '3 months'
        };
        break;
      case 6:
        self.durationSelected = {
          Amount: 1200,
          DurationId: 3,
          RedID: self.userloggedIn,
          Duration: '6 months'
        };
        break;
      case 12:
        self.durationSelected = {
          Amount: 2400,
          DurationId: 4,
          RedID: self.userloggedIn,
          Duration: '12 months'
        };
        break;
      default:
        self.durationSelected = {
          Amount: 200,
          DurationId: 5,
          RedID: self.userloggedIn,
          Duration: '1 month'
        };
        break;
    }
  }

  payMe() {
    const self = this;
    let umtp: UpgradeToPremiumDto = {
      username: self.userloggedIn,
      membershipType: self.durationSelected.DurationId,
      membershipStartDate: new Date(),
      orderStatus: "Paid",
      totalAmount: self.durationSelected.Amount,
      paymentStatus: "Paid",
      paymentMethodId: 1
    }
    self.showLoader = true;
    self.commonservice.upgradeToPremium(umtp).subscribe(
      (srm: SaveResponseModel) => {
        self.showLoader = false;
        if (srm.InsertWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: srm.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: srm.Message });
        }
      },
      error => { }
    );
  }
}
