import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PhotoService {
    getData() {
        return [
            {
                itemImageSrc: '../../assets/image/slide/a.png',
                thumbnailImageSrc: '../../assets/image/slide/a.png',
                alt: 'Description for Image 1',
                title: 'Title 1'
            },
            {
                itemImageSrc: '../../assets/image/slide/b.png',
                thumbnailImageSrc: '../../assets/image/slide/b.png',
                alt: 'Description for Image 1',
                title: 'Title 1'
            },
            {
                itemImageSrc: '../../assets/image/slide/c.png',
                thumbnailImageSrc: '../../assets/image/slide/c.png',
                alt: 'Description for Image 1',
                title: 'Title 1'
            },
            {
                itemImageSrc: '../../assets/image/slide/e.png',
                thumbnailImageSrc: '../../assets/image/slide/e.png',
                alt: 'Description for Image 1',
                title: 'Title 1'
            },
            {
                itemImageSrc: '../../assets/image/slide/f.png',
                thumbnailImageSrc: '../../assets/image/slide/f.png',
                alt: 'Description for Image 1',
                title: 'Title 1'
            }

        ];
    }

    getImages() {
        return Promise.resolve(this.getData());
    }
};