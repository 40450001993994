import { AfterViewChecked, ChangeDetectorRef, Component, CreateEffectOptions, effect, ElementRef, EventEmitter, Input, Output, signal, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { SendMessageRequest } from 'src/app/dto/AddNewChatMessageRequest';
import { ChatResponse } from 'src/app/dto/ChatResponse';
import { IndividualChatmodel } from 'src/app/dto/IndividualChatmodel';
import { ChatUserListResponse } from 'src/app/dto/usersReadyForChat';
import { User } from 'src/app/private/dashboard/home/home.component.domain';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { ChatService } from '../servicecenter/ChatService';
import { SignalRService } from '../servicecenter/SignalRService';

@Component({
  selector: 'app-sender-chat-window-component',
  encapsulation: ViewEncapsulation.ShadowDom,
  templateUrl: './sender-chat-window-component.component.html',
  styleUrl: './sender-chat-window-component.component.css'
})
export class SenderChatWindowComponentComponent implements AfterViewChecked{
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  selectedUser: ChatUserListResponse | null = null;
  isExpanded: boolean = false;
  addText: string = "";
  showLoader: boolean = false;
  showChatWindow: WritableSignal<boolean | null> = signal(false);
  messages: Array<ChatResponse> = []; 
  userloggedIn: string;
  displayName: string = "";
  senderDisplayName:string="";
  public user: User;
  @Input() userData: IndividualChatmodel;
  @Output() close = new EventEmitter<void>();
  @Output() focus = new EventEmitter<void>();

  icm: IndividualChatmodel = {
    AddText: "",
    RecipientDisplayName: "",
    SenderDisplayName:"",
    IsExpanded: true,
    Messages: [],
    ShowLoader: false,
    SenderId: "",
    RecipientId: ""
  };;
  private lastMessageId: any = null;

  onClose(): void {
    this.close.emit();
  }

  constructor(private chatService: ChatService, private signalRService: SignalRService, private localStore: LocalStore, private cdRef: ChangeDetectorRef) {

    effect(() => {
      this.selectedUser = this.chatService.selectedUser();
    }, {
      allowSignalWrites: true
    } as CreateEffectOptions);
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
      // this.glowLatest();
      // Wait briefly before applying the shine effect
      setTimeout(() => {
        // Apply the shine effect only if there's a new message
        const latestMessage = this.icm.Messages[this.icm.Messages.length - 1];
        if (latestMessage && latestMessage.MessageID !== this.lastMessageId) { // Replace with unique property like `MessageID`
          // Trigger view update before adding the shine effect
          this.cdRef.detectChanges();
          this.highlightLatestMessage();
          this.lastMessageId = latestMessage.MessageID; // Update the tracked message ID
        }
      }, 500); // Adjust delay if necessary
    } catch (err) {
      console.log('Scroll to bottom failed:', err);
    }
  }

  private highlightLatestMessage() {
    if (this.chatContainer && this.icm.Messages?.length) {
      const messageElements = this.chatContainer.nativeElement.querySelectorAll('.message');
      const latestMessageElement = messageElements[messageElements.length - 1];

      if (latestMessageElement) {
        // Remove highlight from any previous messages
        messageElements.forEach((el: HTMLElement) => {
          el.classList.remove('highlight-new');
        });

        // Add highlight to the latest message
        latestMessageElement.classList.add('highlight-new');
      }
    }
  }

  ngOnInit() {
    const self = this;
    self.user = {} as User;
    this.icm = this.userData;
    this.loadChatMessages();
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.senderDisplayName = self.localStore.getCurrentLoggedInUserSync().Name;
    this.signalRService.addMessageListener((response, message) => {
      this.loadChatMessages();
    });

    this.signalRService.addMessageConfirmationListener((response, message) => {
      this.loadChatMessages();
    });
  }

  expandList() {
    const self = this;
    this.isExpanded = !this.isExpanded;
    this.icm.IsExpanded = !this.icm.IsExpanded;
  }

  closeWindow(event) {
    event.stopPropagation();
    this.close.emit();
  }

  initiateChat(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.sendMessage();
      event.preventDefault(); // Prevents the default action (newline in textarea)
    }
  }

  sendMessage() {
    const newMessage: SendMessageRequest = {
      SenderID: this.icm.SenderId,
      RecipientID: this.icm.RecipientId, // You need to set this based on your app logic
      MessageContent: this.icm.AddText,
      RecipientDisplayName: this.userData.RecipientDisplayName,
      SenderDisplayName: this.senderDisplayName
    };
    setTimeout(() => this.scrollToBottom(), 0);
    this.signalRService.sendMessage(newMessage);
    this.icm.AddText = "";
  }

  loadChatMessages() {
    console.log("this.icm", this.icm);
    const sender = this.icm.SenderId;
    const recipient = this.icm.RecipientId; // You need to set this based on your app logic
    if (sender) {
      this.chatService.getChatMessages(recipient, sender).subscribe(
        (messages: ChatResponse[]) => {
          this.messages = [];
          this.messages.push(...messages);
          this.userData = this.icm;
          this.addText = "";
          this.icm.Messages = [];
          this.icm.Messages.push(...messages);
          this.displayName= this.icm.RecipientDisplayName;
          this.showLoader = false;
          if (!this.icm.IsExpanded) {
            this.icm.IsExpanded = true;
          }
          this.cdRef.detectChanges();
        }
      );
    }
  }

  // private scrollToBottom(): void {
  //   try {
  //     this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  //   } catch (err) {
  //     console.log('Scroll to bottom failed:', err);
  //   }
  // }

  loadChatMessagesForSpecificUser(recipient: string) {
    const sender = this.chatService.selectedUser()?.UserID;
    if (sender) {
      this.chatService.getChatMessages(sender, recipient).subscribe(
        (messages: ChatResponse[]) => {
          this.messages = [];
          this.messages.push(...messages);
          this.addText = "";
          this.showLoader = false;
        }
      );
    }
  }
}
