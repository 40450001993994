import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import * as _ from 'lodash';
import { catchError, debounceTime, map, Observable, of } from "rxjs";
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';

export function selectOneValidatorRegister(c: AbstractControl): { [key: string]: boolean } | null {
    let theValueField = c["value"];
    if (theValueField != undefined) {
        if (Number(theValueField) === 0 && c.touched) {
            return { 'MinimumOneSelection': true };
        }
        else {
            return null;
        }
    }

    return { 'MinimumOneSelection': true };
}

export function EmailValidator(c: AbstractControl): { [key: string]: boolean } | null {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(c.value).toLowerCase())) {
        return null;
    }
    return { 'EmailValidationFailed': true };
}

export function EmailAsyncValidator(commonService: Commonservice): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: boolean } | null> => {
        const email = control.value;

        if (!email) {
            // No email, no need to validate
            return of(null);
        }

        return commonService.IsEmailRegistered(email).pipe(
            debounceTime(300), // Optional: delay for better performance
            map(isRegistered => {
                return isRegistered ? { 'emailExists': true } : null;
            }),
            catchError(() => of(null))
        );
    };
}

export function PhoneAsyncValidator(commonService: Commonservice): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: boolean } | null> => {
        const email = control.value;

        if (!email) {
            // No email, no need to validate
            return of(null);
        }

        return commonService.isPhoneNumberRegistered(email).pipe(
            debounceTime(300), // Optional: delay for better performance
            map(isRegistered => {
                return isRegistered ? { 'phoneExists': true } : null;
            }),
            catchError(() => of(null))
        );
    };
}

export function MinimumOneSelection(c: AbstractControl): { [key: string]: boolean } | null {
    if (Number(c.value) > 0) {
        return null;
    }
    return { 'MinimumOneSelection': true };
}

export function CheckBoxValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (c.value) {
        return null;
    }
    return { 'CheckMandatory': true };
}

export function FindInvalidControls(customForm) {
    const invalid = [];
    if (customForm !== undefined) {
        const controls = customForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
    }
    return invalid;
}


export function DateValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (_.isDate(c.value)) {
        return null;
    }
    return { 'DateValdationFail': true };
}

export function WorkExperienceSelectOneValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (Number(c.value.value) > 0) {
        return null;
    }
    return { 'MinimumOneSelection': true };
}

export function MinimumOneSelection2(c: AbstractControl): { [key: string]: boolean } | null {
    if (Number(c.value.value) > 0) {
        return null;
    }
    return { 'MinimumOneSelection': true };
}

export interface dropdown {
    name?: string;
    value?: number;
}
export function selectOneValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (Number(c.value.value) > 0) {
        return null;
    }
    return { 'MinimumOneSelection': true };
}

export function passwordMatcher(c: AbstractControl): { [key: string]: boolean } | null {
    const passwordControl = c.get('employerpassword');
    const confirmPasswordControl = c.get('employerconfirmpassword');
    const invalidControl = FindInvalidControls(c.parent);
    if (passwordControl.pristine || confirmPasswordControl.pristine) {
        return null;
    }

    if (passwordControl.value === confirmPasswordControl.value) {
        return null;
    }
    return { 'match': true };
}