import { Injectable, signal } from '@angular/core';
import * as MobileDetect from 'mobile-detect';

interface DeviceConfig {
  name: string;
  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private readonly devices: Record<string, DeviceConfig[]> = {
    portraitDevices: [
      { name: 'iPhoneSE', minWidth: 350, maxWidth: 400, minHeight: 650, maxHeight: 700 },
      { name: 'iPhoneXR', minWidth: 400, maxWidth: 450, minHeight: 700, maxHeight: 900 },
      { name: 'iPhone12Pro', minWidth: 350, maxWidth: 400, minHeight: 800, maxHeight: 850 },
      { name: 'iPhone14ProMax', minWidth: 400, maxWidth: 450, minHeight: 900, maxHeight: 950 },
      { name: 'Pixel7', minWidth: 400, maxWidth: 450, minHeight: 900, maxHeight: 950 },
      { name: 'GalaxyS8Plus', minWidth: 350, maxWidth: 400, minHeight: 700, maxHeight: 750 },
      { name: 'GalaxyS20Ultra', minWidth: 400, maxWidth: 450, minHeight: 900, maxHeight: 950 },
      { name: 'GalaxyA51A71', minWidth: 400, maxWidth: 450, minHeight: 900, maxHeight: 950 },
    ],
    tabletDevices: [
      { name: 'iPadMini', minWidth: 750, maxWidth: 800, minHeight: 1000, maxHeight: 1050 },
      { name: 'iPadAir', minWidth: 800, maxWidth: 850, minHeight: 1150, maxHeight: 1200 },
      { name: 'iPadPro', minWidth: 1000, maxWidth: 1050, minHeight: 1350, maxHeight: 1400 },
      { name: 'SurfacePro7', minWidth: 900, maxWidth: 950, minHeight: 1350, maxHeight: 1400 },
    ],
    foldableDevices: [
      { name: 'SurfaceDuo', minWidth: 500, maxWidth: 550, minHeight: 700, maxHeight: 750 },
      { name: 'GalaxyZFold5', minWidth: 350, maxWidth: 400, minHeight: 800, maxHeight: 850 },
      { name: 'AsusZenbookFold', minWidth: 950, maxWidth: 1000, minHeight: 1250, maxHeight: 1300 },
    ],
    landscapeDevices: [
      { name: 'iPhoneSE', minWidth: 650, maxWidth: 700, minHeight: 350, maxHeight: 400 },
      { name: 'iPhoneXR', minWidth: 850, maxWidth: 900, minHeight: 400, maxHeight: 450 },
      { name: 'iPhone12Pro', minWidth: 800, maxWidth: 850, minHeight: 350, maxHeight: 400 },
      { name: 'iPhone14ProMax', minWidth: 900, maxWidth: 950, minHeight: 400, maxHeight: 450 },
      { name: 'Pixel7', minWidth: 900, maxWidth: 950, minHeight: 400, maxHeight: 450 },
      { name: 'GalaxyS8Plus', minWidth: 700, maxWidth: 750, minHeight: 350, maxHeight: 400 },
      { name: 'GalaxyS20Ultra', minWidth: 900, maxWidth: 950, minHeight: 400, maxHeight: 450 },
      { name: 'GalaxyA51A71', minWidth: 900, maxWidth: 950, minHeight: 400, maxHeight: 450 },
    ],
    desktopDevices: [
      { name: 'DesktopFullHD', minWidth: 1900, maxWidth: 1950, minHeight: 900, maxHeight: 950 },
      { name: 'DesktopQuadHD', minWidth: 2550, maxWidth: 2600, minHeight: 1400, maxHeight: 1450 },
      { name: 'Desktop4K', minWidth: 3800, maxWidth: 3850, minHeight: 2150, maxHeight: 2200 },
    ],
    laptopDevices: [
      { name: 'Laptop13', minWidth: 1280, maxWidth: 1366, minHeight: 720, maxHeight: 768 },
      { name: 'Laptop14', minWidth: 1366, maxWidth: 1440, minHeight: 768, maxHeight: 900 },
      { name: 'Laptop15', minWidth: 1440, maxWidth: 1680, minHeight: 900, maxHeight: 1050 },
      { name: 'Laptop16', minWidth: 1680, maxWidth: 1920, minHeight: 1050, maxHeight: 1200 },
    ],
  };

  deviceSignals: { [key: string]: ReturnType<typeof signal<boolean>> } = {};

  constructor() {
    this.initializeSignals();
    this.checkScreenSizeAndOrientation();

    window.addEventListener('resize', () => this.checkScreenSizeAndOrientation());
    window.addEventListener('orientationchange', () => this.checkScreenSizeAndOrientation());
  }

  private initializeSignals(): void {
    Object.values(this.devices).flat().forEach(device => {
      this.deviceSignals[device.name] = signal<boolean>(false);
    });
  }

  checkScreenSizeAndOrientation(): void {
    const { innerWidth: width, innerHeight: height } = window;
    const isPortrait = height > width;

    const devicesToCheck = isPortrait
      ? [...this.devices.portraitDevices, ...this.devices.tabletDevices, ...this.devices.foldableDevices]
      : [...this.devices.landscapeDevices, ...this.devices.laptopDevices, ...this.devices.desktopDevices];

    Object.keys(this.deviceSignals).forEach(key => this.deviceSignals[key].set(false));

    devicesToCheck.forEach(device => {
      if (this.isWithinRange(width, height, device)) {
        this.deviceSignals[device.name].set(true);
      }
    });
  }

  private isWithinRange(width: number, height: number, device: DeviceConfig): boolean {
    return width >= device.minWidth && width <= device.maxWidth &&
      height >= device.minHeight && height <= device.maxHeight;
  }

  private getDetectedDeviceCategory(): string {
    if (this.isMobileDevice()) return 'mobile';
    if (this.isTabletDevice()) return 'tablet';
    if (this.isFoldableDevice()) return 'foldable';
    if (this.isLaptopDevice()) return 'laptop';
    if (this.isDesktopDevice()) return 'desktop';
    return 'unknown';
  }

  isPortrait(): boolean {
    return window.innerHeight > window.innerWidth;
  }

  isLandscape(): boolean {
    return window.innerWidth > window.innerHeight;
  }

  isMobileDevice(): boolean {
    return this.devices.portraitDevices.some(device => this.deviceSignals[device.name]());
  }

  isTabletDevice(): boolean {
    return this.devices.tabletDevices.some(device => this.deviceSignals[device.name]());
  }

  isFoldableDevice(): boolean {
    return this.devices.foldableDevices.some(device => this.deviceSignals[device.name]());
  }

  isDesktopDevice(): boolean {
    return this.devices.desktopDevices.some(device => this.deviceSignals[device.name]());
  }

  isLaptopDevice(): boolean {
    return this.devices.laptopDevices.some(device => this.deviceSignals[device.name]());
  }

  getCurrentScreenSize(): { width: number; height: number } {
    return { width: window.innerWidth, height: window.innerHeight };
  }

  getDetectedDevices(): string[] {
    return Object.entries(this.deviceSignals)
      .filter(([_, signal]) => signal())
      .map(([name]) => name);
  }

  getCurrentScreenInfo() {
    const md = new MobileDetect(window.navigator.userAgent);
    const width = window.screen.width;
    const height = window.screen.height;
    const pixelRatio = window.devicePixelRatio;

    const detectedDevices = [];
    let deviceCategory = '';

    // Detecting iPhone XR
    if (md.is('iPhone') && width === 414 && pixelRatio === 2) {
        detectedDevices.push('iPhone XR');
        deviceCategory = 'Mobile';
    } else if (md.is('iPhone')) {
        detectedDevices.push('iPhone');
        deviceCategory = 'Mobile';
    } else if (md.is('Android')) {
        detectedDevices.push('Android');
        deviceCategory = 'Mobile';
    } else if (md.is('Tablet')) {
        detectedDevices.push('Tablet');
        deviceCategory = 'Tablet';
    } else if (width > 768) { // Typical laptop breakpoint
        detectedDevices.push('Laptop');
        deviceCategory = 'Laptop';
    } else {
        detectedDevices.push('Desktop');
        deviceCategory = 'Desktop';
    }

    return {
        size: {
            width: width,
            height: height
        },
        orientation: window.orientation || (width > height ? 'landscape' : 'portrait'),
        detectedDevices: detectedDevices,
        deviceCategory: deviceCategory
    };
}
}
