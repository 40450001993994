<div id="sendMessage" [style.display]="mc.show == true ? 'block' : 'none'" class="w3-modal">
    <div class="w3-modal-content w3-animate-zoom w3-card-4">
      <header class="w3-container w3-teal">
        <span (click)="hideModel()" class="w3-button w3-display-topright">&times;</span>
  
        <div class="header-section">{{mc.header}} </div>
      </header>
      <div class="w3-container">
  
        <div class="barelist">
          <div>
            <textarea rows="4" cols="45" class="super-input-box" placeholder="" [(ngModel)]="mc.modelContent"></textarea>
          </div>
          <div>          
            <div class='row'>
              <div class='col-md-2'>
                <button type='button' (click)='sendMessage(mc.id,mc.modelContent)' pButton class="my-button space-it" color="primary">SEND</button>
              </div>
              <div class='col-md-2'>
                  <button type='button' (click)='cancelWindow()' pButton class="my-button space-it" color="primary">CANCEL</button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="w3-container w3-teal text-right">
        {{mc.modelFooter }}
      </footer>
    </div>
  </div>