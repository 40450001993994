import { Routes } from '@angular/router';
import { QuickSearchComponent } from './quicks.component';
import { GaurdService } from '../../../common/reusable/servicecenter/gaurdService.component';



// Route Configuration
export const QuickSearchRoute: Routes = [
  { path: 'quicksearch', canActivate: [GaurdService], component: QuickSearchComponent }
];
