import { Injectable, signal, computed, effect, EffectRef, OnDestroy } from '@angular/core';
import { debounce } from 'lodash-es';
import * as CryptoJS from 'crypto-js';
import { secretKey } from '../contants/app.constants';

interface State {
    [key: string]: any;
}

@Injectable({
    providedIn: 'root',
})
export class SignalStateService implements OnDestroy {
    private state = signal<State>(this.getInitialState());
    private saveEffectRef: EffectRef | null = null;

    constructor() {
        this.loadFromSessionStorage();
        this.setupSaveEffect();
    }

    private setupSaveEffect() {
        const debouncedSave = debounce(() => {
            const currentState = this.state();
            sessionStorage.setItem('appState', JSON.stringify(currentState));
        }, 300);

        this.saveEffectRef = effect(() => {
            const _ = this.state(); // Trigger the effect on any state change
            debouncedSave();
        });
    }

    private loadFromSessionStorage() {
        const storedState = sessionStorage.getItem('appState');
        try {
            if (storedState) {
                let decryptedState: any;
                if (typeof (storedState) === 'string') {
                    decryptedState = JSON.parse(storedState);
                } else {
                    decryptedState = JSON.parse(this.decrypt(storedState));
                }

                this.state.set(decryptedState);
            } else {
                this.state.set({});
            }
        } catch (error) {
            console.error('Error loading state from session storage:', error);
            this.state.set({});
        }
    }

    setValue(key: string, value: any) {
        this.state.update((state) => {
            const newValue = typeof value === 'object' ? { ...state[key], ...value } : value;
            const encryptedValue = this.encrypt(newValue);
            return {
                ...state,
                [key]: encryptedValue,
            };
        });
    }

    getValue(key: string) {
        return computed(() => {
            const encryptedValue = this.state()[key];
            return encryptedValue ? this.decrypt(encryptedValue) : null;
        });
    }

    getState() {
        return this.state.asReadonly();
    }

    private encrypt(value: any): string {
        return CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
    }

    private decrypt(cipherText: string): any {
        const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData ? JSON.parse(decryptedData) : null;
    }

    ngOnDestroy() {
        if (this.saveEffectRef) {
            this.saveEffectRef.destroy();
        }
    }



    private getInitialState(): State {
        const isEmpty = (obj: object): boolean => {
            return Object.entries(obj)?.length === 0;
        };
        const storedState = sessionStorage.getItem('appState');        
        if (storedState && storedState!=='null' && !isEmpty(JSON.parse(storedState))) {
            try {
                const decryptedState = this.decrypt(JSON.parse(storedState));
                return JSON.parse(decryptedState);
            } catch (error) {
                console.error('Error loading state from session storage:', error);
            }
        }
        return {};
    }

    clearAll() {
        this.state.set({});
        sessionStorage.removeItem('appState');
    }
}